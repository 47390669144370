<template>
    <div :id="alertOffsetContainerId"
         class="contract-checker-container">
<!--        v-loading="loading"-->
<!--        element-loading-spinner="el-icon-loading"-->
<!--        element-loading-text="正在审查，请稍后..."-->
<!--        element-loading-background="rgb(248, 249, 250)" :style="{top: loadingTop}"-->
      <CheckerInvisibleTipGroup
          :thresholds="{top:0,bottom:0}"
          :position="{top:135,bottom:0}"
          :tip-positions="{top:{top:10,left:100,},bottom:{bottom:35,left:100,}}"
          v-show="showContractChecker"
          v-if="!loading"
      ></CheckerInvisibleTipGroup>
        <div v-show="!loading"
             ref="scrollPanel" :style="{'margin-top':alertsOffsetTop + 'px'}"
             style="transition: margin-top ease-in-out 0.3s;padding:0 5px;">
            <div v-show="checkSuccess">
                <contract-alert-box
                        v-for="alert in alertData"
                        :alert-info="alert"
                        :key="alert.id"
                        @alert-click="alertClick"
                        :ref="'alertInfoBox'+alert.id"
                        @alert-child-block-clicked="alertHitChildBlockClicked"
                        @show-clause-text="showClauseTextDialog"
                        :feed-back-hash="feedBackHash"
                >
                </contract-alert-box>
            </div>
            <div v-show="!checkSuccess" style="margin-top: 14px">
                审查失败
            </div>
        </div>

        <div v-if="contractSetting && showContractChecker">
            <ContractSetting
                    ref="contract-setting"
                    :setting="contractSetting"
                    @setting-changed="onSettingChanged">
            </ContractSetting>
        </div>
        <standard-clause-dialog  ref="standardClauseDialog"></standard-clause-dialog>
      <div class="tagContainer" ref="tagContainer" v-if="activeBlockTag&&!scrolledAfterBlockTagClick" :style="{top:activeBlockTagPosition.top+'px',left:activeBlockTagPosition.left+'px'}" style="position:fixed;">
        <div >
          <div v-for="item in activeBlockTag.labels" :key="item">{{ item }}</div>
        </div>
      </div>
    </div>
</template>

<script>
    import {checkContract,userConfirmLaunchReview} from "../../api/api"
    import ContractAlertBox from "../checker/ContractAlertBox";
    import ContractSetting from "./ContractSetting";
    import StandardClauseDialog from "../checker/StandardClauseDialog";

    import {
        AlertDisplayStatus,
        AlertExtensionType,
        AlertType,
        BlockMessageType,
        BUSY_ERROR_CODE,
        CheckMode,
        ErrorType,
        ErrorTypeMenuItemKey,
        ExpandDisplayType,
        ExtensionDirection,
        getAlertShowType,
        RESULT_CODE,
        AlertShowType,
        HighlightMode
    } from "../../constant/alert";
    import * as $ from "jquery";
    import {mergeRectsByVerticalShowType} from "../../assets/js/alert";
    import {checkerMixin} from "./mixin";
    import CheckerInvisibleTipGroup from "../checker/CheckerInvisibleTipGroup";
    import {colorLog} from "../../util";
    import {ElMessageBox} from "element-plus";
	const EMIT_EVENT_NAME={
      CONTRACT_CHECK_PROGRESS_RATIO_UPDATED:'contract-check-progress-ratio-updated',
      CHECKER_LOADING_CHANGE: 'checker-loading-change'
    }
    export default {
        name: "ContractCheckReadMode",
        props: ['contractId', 'pdfContent', 'pdfInfo', 'showContractChecker'],
        components: {CheckerInvisibleTipGroup, ContractAlertBox, ContractSetting, StandardClauseDialog},
        mixins:[checkerMixin],
        data() {
            return {
                contractSetting: null,
                loading: false,
                alerts: [],
                alertOffsetContainerId: 'contract-alert-container',
                containerHeight: 500,
                alertsOffsetTop: 0,
                checked: false,
                ErrorType: ErrorType,
                AlertType: AlertType,
                ExpandDisplayType: ExpandDisplayType,
                AlertDisplayStatus: AlertDisplayStatus,
                editorPosition: {top: 0, left: 0, width: 0, height: 0},
                preCheckText: '',
                words: [],
                alertData: [],
                alertDataMap: {},
                allAlertData: [],
                // 纠错中
                checkStatus: {
                    checking: false,
                    updateTime: new Date()
                },
                docId: -1,
                fileId: -1,
                drawer: true,
                // userProductType: 0,
                userProduct: {
                    productType: 0
                },
                isTeamActive: 0,
                superUser: 0,
                showAlertType: ErrorType.ALL,
                showIgnoreAlert: false,
                checkLoading: false,
                waitCheck: true,
                checkTranslationOverLimitDrawer: false,
                checkOverLimitDrawer: false,
                translationOverLimitDrawer: false,
                rewriteOverLimitDrawer: false,
                checkTranslationOverLimitDrawerDirection: 'btt',
                overLimitTitle: '',
                overLimitDescription: '',
                checkOverTitle: '',
                checkOverDescription: '',
                translateOverTitle: '',
                translateOverDescription: '',
                rewriteOverTitle: '',
                rewriteOverDescription: '',
                alertHighlightContainerClass: 'alertLayer',
                alertHighlightClass: 'alert-highlightContainer',
                errorStatistic: {},
                topShowMoreAlert: false,
                bottomShowMoreAlert: false,
                freezeScrollListener:false,//是否冻结点对"显示更多提示"的判断。
                unFreezeScrollListenerTimeout:null,//冻结后需要解冻，这个是解冻的定时器
                // includeErrorType:[ErrorType.TYPO,ErrorType.PUNCTUATION_MISUSE,ErrorType.MAIN_RISK,ErrorType.LAW_RISK],
                highlightMode: HighlightMode.ALERT,
                hasShowQuestionUserTypeDiv:false,
                clauseDialogVisible:false,
                clauseDialogTexts:[],
                settingJSONString: null,
                forceCheck: false,
                contractAlterBoxRefs:[],
                baseLoadingTop: '20px',
                loadingTop: '20px',
                checkSuccess: true,
                needReCheck: false,
                feedBackHash:null,
              checkHighlightLayerClassName:'check-highlight-layer',
              blockTagHighlightLayerClassName:'block-tag-highlight-layer',
              blockTagHighlightClass: 'blockTagHighlightContainer',
              blockTags:[],
              activeBlockTag:null,
              scrolledAfterBlockTagClick:false,
              activeBlockTagPosition:{top:0,left:0},
              openBlockTag:this.$route.query.obt,
            }
        },
        created() {
            console.log('contractCheckReadMode  created --------------- ')
        },
        mounted() {
          colorLog('pdfInfo',this.pdfInfo);
            console.log('contractCheckReadMode  mounted --------------- ')
            const windowHeight = $(window).height();
            this.containerHeight = windowHeight - 164;
            this.baseLoadingTop = (windowHeight - 108 - 42 - 50 - 10) / 2 - 155 + 'px';
            this.loadingTop = this.baseLoadingTop;
            this.initOpenBlockTag();
        },
        beforeUnmount() {
        },
        watch: {
            alertData(val) {
                console.log('alertData  change  val ==== ', val);
                this.refreshAlertDataMap(val)
                if (this.highlightMode === HighlightMode.ALERT) {
                    this.refreshErrorHighlight(val);
                }
                const alertHighlightJItem =$(`.${this.alertHighlightContainerClass}`);
                if (alertHighlightJItem.length > 0) {
                    // alertHighlightJItem.show();
                    alertHighlightJItem.attr("style", "opacity: 1;");
                }
                // this.hideOrShowDownloadButton(val)
            },
          blockTags(val){
              this.refreshBlockTagHighlight(val);
          },
            loading(val){
              console.log('loading-change***'+val)
                this.$emit('checker-loading-change',val)
            }
        },
      computed:{
        blockTagsMap() {
          let map = new Map();
          for (let i = 0; i < this.blockTags.length; i++) {
            map.set(this.blockTags[i].id, this.blockTags[i]);
          }
          return map;
        }
      },
        methods: {
          initOpenBlockTag:function (){
            let queryObt = this.$route.query.obt
            // console.log('obt:',queryObt)
            if (queryObt !== undefined) {
              localStorage.setItem('obt',queryObt);
            }
            console.log('obt:' + queryObt);
            let storageObt = localStorage.getItem('obt');
            this.openBlockTag = (storageObt == null ? false : storageObt === '1');
          },
          init(){
            this.showLoading();
            this.getContractAlerts();
            this.addEditorEventListener();
            this.initAlertsContainerInitOffsetTop();
          },
            onSettingChanged: function (settingJSONString) {
                console.log('onSettingChanged  settingJSONString === ', settingJSONString);
                this.settingJSONString = settingJSONString;
                this.forceCheck = true;
                this.showLoading();
                this.getContractAlerts();
            },
            setLoading:function (loading){
               this.loading = loading;
              this.$emit('checker-loading-change',loading)
            },
            showLoading: function () {
                this.contractSetting = null;
                this.alertData = [];
                this.setLoading(true)
                this.loadingTop = this.baseLoadingTop;
                this.$emit(EMIT_EVENT_NAME.CHECKER_LOADING_CHANGE, true);
            },
            addContractAlterBoxRef(ref){
              this.contractAlterBoxRefs.push(ref);
            },
            removeContractAlterBoxRef(ref){
              this.contractAlterBoxRefs = this.contractAlterBoxRefs.filter(_ref => _ref !== ref);
            },
            settingChangForceCheck: function (settingJSONString) {
                if (this.loading) {
                    // 上一次审查还未结束
                    this.needReCheck = true;
                } else {
                    this.showLoading();
                    this.getContractAlerts(settingJSONString);
                }
            },
            getContractAlerts: function (settingJSONString) {
            console.error('*********************get contract alerts')
              if (this.checkStatus.checking) {
                // 如果正处于loading状态，则等待结果即可。如果要强行检查，调上面的forceCheck方法。
                return;
              }
              this.getContractAlertsFunc(settingJSONString);
            },
          getContractAlertsFunc:function (settingJSONString){
            this.checked = true;
            this.checkStatus.checking = true;
            const contractCheckParam = {
              content: this.pdfContent,
              checkMode: 0,
              settingJSONString: settingJSONString || this.settingJSONString,
              forceCheck: settingJSONString ? true : this.forceCheck,
              openBlockTag:!!this.openBlockTag
            };
            checkContract(this.contractId, contractCheckParam).then(res => {
              console.log('contractCheck res:', res);
              this.$emit(EMIT_EVENT_NAME.CONTRACT_CHECK_PROGRESS_RATIO_UPDATED,res.data.data.progressRatio);
              if (res.data.data.code === RESULT_CODE.SUCCESS) {
                if (res.data.data.xieZuoCatCheckResult && res.data.data.xieZuoCatCheckResult.checkFinished) {
                  this.checkStatus.checking = false;
                  if (this.needReCheck) {
                    this.needReCheck = false;
                    this.getContractAlerts();
                    return;
                  }
                  this.checkSuccess = true;
                  this.loadingFinish();
                  this.alerts = res.data.data.allAlerts;
                  this.contractSetting = res.data.data.setting;
                  this.feedBackHash = res.data.data.feedBackHash;
                  //刷新错误信息
                  let newData = this.alerts
                  if (newData) {
                    this.refreshAlertData(newData)
                  }
                  this.refreshBlockTag(res.data.data.bts);
                  const contentContenter = document.querySelector('#pdf-container');
                  this.alertsOffsetTop = contentContenter.scrollTop - 54;
                } else {
                  setTimeout( () =>{
                    console.log('写作猫检查未完成，继续检查.....');
                    this.getContractAlertsFunc();
                  }, 1000);
                }
              } else if (res.data.data.code === RESULT_CODE.WAITING) {
                setTimeout( () =>{
                  console.log('继续检查.....');
                  this.getContractAlertsFunc();
                }, 1000);
              } else if (res.data.data.code === RESULT_CODE.WAITING_NEW) {
                this.forceCheck = false;
                this.settingJSONString = null;
                setTimeout( () =>{
                  console.log('继续检查.....');
                  this.getContractAlertsFunc();
                }, 1000);
              }else if(res.data.data.code === RESULT_CODE.WAITING_FOR_CONFIRMATION){
                  this.$emit('error',
                        () => {
                            userConfirmLaunchReview(this.contractId).then(res =>{
                                if (res.data.code !== 0){
                                    this.checkSuccess = false;
                                }
                            }).catch(err => {
                                console.error('userConfirmLaunchReview  err:', err);
                            })
                        },
                        () => {
                            this.loadingFinish();
                            this.checkSuccess = false;
                        });
              }else if(res.data.data.code === RESULT_CODE.WAITING_PURCHASE_PACKAGE){
                  this.contractApprovalEvent = {
                      class: 'message-red',
                      title: `您团的份额已使用完`,
                      msg: `您的团队合同份额已使用完毕,暂时无法加载概览与审查,\n请点击下方按钮购买或直接联系管理员`,
                      buttonDisplay: "upgrade"
                  }
                  this.loadingFinish();
                  this.checkSuccess = false;
              }else if(res.data.data.code === RESULT_CODE.WAITING_REFRESH_TO_LOAD){
                  this.contractApprovalEvent = {
                      class: 'message-blue',
                      title: `您团队的份额已刷新`,
                      msg: `您的团队合同份额已刷新,可以加载概览与审查,\n请点击下方按钮刷新后查看`,
                      buttonDisplay: "refresh"
                  }
              } else {
                this.loadingFinish();
                this.checkSuccess = false;
                this.checkStatus.checking = false;
                console.error('contractCheck  failed:', res)
              }
            }).catch(err => {
              this.loadingFinish();
              this.checkSuccess = false;
              this.checkStatus.checking = false;
              console.error("contractCheck  err:", err);
            })
          },
            loadingFinish: function () {
              console.log('loading finish**************')
              this.setLoading(false)
                this.settingJSONString = null;
                this.forceCheck = false;
                this.loadingTop = '20px';
                this.$emit(EMIT_EVENT_NAME.CHECKER_LOADING_CHANGE, false);
            },
            clear:function(){
                this.alertData = [];
                this.allAlertData = [];
                this.words= []
                this.waitCheck = true
                this.checkStatus= {
                    checking: false,
                    updateTime: new Date()
                }
                this.checkLoading = false
                this.docId = -1
                this.fileId = -1
                this.alertsOffsetTop=0
                this.checkTranslationOverLimitDrawer= false
                this.checkOverLimitDrawer= false
                this.translationOverLimitDrawer= false
                this.rewriteOverLimitDrawer= false
                this.checkTranslationOverLimitDrawerDirection= 'btt'
                this.overLimitTitle= ''
                this.overLimitDescription= ''
                this.checkOverTitle= ''
                this.checkOverDescription= ''
                this.translateOverTitle= ''
                this.translateOverDescription= ''
                this.rewriteOverTitle= ''
                this.rewriteOverDescription= ''
                this.preCheckText= ''
                this.topShowMoreAlert = false
                this.bottomShowMoreAlert = false
                this.freezeScrollListener = false
                this.showAlertType = ErrorType.ALL
                this.showIgnoreAlert = false
                this.refreshErrorStatistic(this.allAlertData)
                //将右边错误提示的高度重置。
                $("#pdfExtend").css('min-height', '0px');
            },
            restorePosition: function (){
                this.editorScrollToAbsolutePosition(0,-1)
                this.alertsOffsetTop = 0
            },
            showMessage:function(text,level='info'){
                this.$message({
                    message: text,
                    type: level
                });
            },
            /**
             * 重新检查
             */
            reCheck: function () {
                return this.checkContent(CheckMode.RE_CHECK,{fileId:this.fileId, pdfContent: this.preCheckText})
            },
            showClauseTextDialog:function(clauseTextString){
                this.$refs['standardClauseDialog'].showClauseTextDialog(clauseTextString)
            },
            goToUpgrade: function () {
                // top.location
                top.open(this.upgradeUrl)
            },
            addEditorEventListener: function () {
                let self = this
                this.getEditorElement().off('click').on('click',function (e) {
                    if (!(e.pageX || e.pageY)) {
                        // 如果不是一个正常的点击事件（有可能是有focus触发的）
                        return
                    }
                    self.judgeHighlightClick(e)
                    self.judgeBlockTagHighlightClick(e)
                })
                this.getScrollerJItem().off('scroll').on('scroll',()=>{
                  this.scrolledAfterBlockTagClick=true;
                })
            },
            onScroll: function (e) {
                if (this.freezeScrollListener) {
                    this.hideMoreAlert()
                    return
                }
                this.showMoreAlertIfNeed()
            },
            freezeScrollListenerFunc: function (time) {
                let self = this
                self.freezeScrollListener = true
                if(this.unFreezeScrollListenerTimeout){
                    clearTimeout(this.unFreezeScrollListenerTimeout)
                }
                self.unFreezeScrollListenerTimeout = setTimeout(() => {
                    self.freezeScrollListener = false
                }, time)
            },
            showMoreAlertClicked: function () {
                let self = this
                let minDiff = null
                let recentAlert = null
                let middleOffset = document.body.clientHeight / 2 + self.getScrollerOffsetTop() / 2
                self.alertData.forEach(alertInfo => {
                    let highlightItem = self.getAlertHighlightJItem(alertInfo.id)
                    if (highlightItem.length === 0) {
                        //如果length为0，说明错误提示的那一页还没加载出来，不用理会。
                        return
                    }
                    let highlightItemOffsetTop = highlightItem.offset().top;
                    let diff = Math.abs(highlightItemOffsetTop - middleOffset)
                    if (minDiff == null || minDiff > diff) {
                        minDiff = diff
                        recentAlert = alertInfo
                    }
                })
                if (recentAlert === null) {
                    if (self.topShowMoreAlert) {
                        self.hideMoreAlert()
                        self.scrollViewIntoAlert(self.alertData[self.alertData.length - 1])
                    } else if (self.bottomShowMoreAlert) {
                        self.hideMoreAlert()
                        self.scrollViewIntoAlert(self.alertData[0])
                    }
                } else {
                    self.hideMoreAlert()
                    self.scrollViewIntoAlert(recentAlert)
                }
            },
            /**
             * 将提示移动到可以看到的地方。
             * @param alert 提示
             */
            scrollViewIntoAlert:function(alert){
                if (alert.showType === AlertShowType.DOC) {
                    //如果错误提示本身是不会在文章中划线的，那么只有把错误提示移动到可以看到的区域。
                    let preAlertBoxHeight = this.getPreAlertHeight(alert.id);
                    this.alertsOffsetTop = this.getScrollerOffsetTop() + this.getScrollerScrollTop() + $(window).height() * 0.5 - this.getFirstAlertOffsetOfScreenTop() - preAlertBoxHeight;
                }
                this.alertClick(alert);
            },
            showMoreAlertIfNeed: function () {
                let self = this
                self.hideMoreAlert()
                if (self.alertData === null || self.alertData.length === 0) {
                    // self.hideMoreAlert()
                    return
                }
                let firstAlert = self.getAlertBoxById(self.alertData[0].id)
                let lastAlert = self.getAlertBoxById(self.alertData[self.alertData.length - 1].id)
                if (firstAlert === undefined || lastAlert === undefined) { // 为了解决数据加载延迟导致的报错
                    return
                }

                let firstAlertOffsetTop = firstAlert.offset().top
                let lastAlertOffsetTop = lastAlert.offset().top
                let lastAlertHeight = lastAlert.height()
                if (lastAlertOffsetTop + lastAlertHeight < self.getFirstAlertOffsetOfScreenTop()) {
                    self.showMoreAlertInTop()
                } else if (firstAlertOffsetTop > document.body.clientHeight) {
                    self.showMoreAlertInBottom()
                } else {
                    self.hideMoreAlert()
                }
            },
            showMoreAlertIfNeedDelay:function(judgeTimeout){
                const self = this;
                setTimeout(() => {
                    self.showMoreAlertIfNeed();
                }, judgeTimeout);
            },
            hideMoreAlert: function () {
                this.topShowMoreAlert = false
                this.bottomShowMoreAlert = false
            },
            showMoreAlertInTop: function () {
                this.topShowMoreAlert = true
                this.bottomShowMoreAlert = false
                // this.refreshMoreAlertPosition()
            },
            showMoreAlertInBottom: function () {
                this.topShowMoreAlert = false
                this.bottomShowMoreAlert = true
            },
            // 判断是否点击到错误文字，并决定是否触发点击事件。
            judgeHighlightClick: function (e) {
                let alertHighlights = this.getAlertHighlights();
                let clickAlertIds = []
                //获取点击点在哪些alert的高亮区域里面
                for (let i = 0; i < alertHighlights.length; i++) {
                    let highlightEleRect = alertHighlights[i].getBoundingClientRect()
                    if (e.clientX <= highlightEleRect.right && e.clientX >= highlightEleRect.left - 3
                        && e.clientY <= highlightEleRect.bottom + 3 && e.clientY >= highlightEleRect.top) {
                        // this.highlightClick($(alertHighlights[i]).attr('data-id'), 50);
                        // break;
                        clickAlertIds.push($(alertHighlights[i]).attr('data-id'))
                    }
                }
                if (clickAlertIds.length === 0) {
                    return
                }
                if (this.highlightMode === HighlightMode.ALERT_BLOCK) {
                    // alert——block模式下，只有一个alert的高亮，所以直接取第一个就可以了
                    this.highlightClick(clickAlertIds[0], 50);
                    return;
                }
                let minStartAlertId = null;
                let maxStart = -1;

                for (let i = 0; i < clickAlertIds.length; i++) {
                    let alert = this.getAlertById(clickAlertIds[i]);
                    //这里忽略了多个markRange的情况（要想彻底解决这个问题还是得找到点击的地方在哪个字周围）
                    if (alert.markRanges[0].start > maxStart) {
                        //当前提示的start更大，更精准
                        maxStart = alert.markRanges[0].start
                        minStartAlertId = clickAlertIds[i]
                    }
                }
                if(minStartAlertId){
                    this.highlightClick(minStartAlertId, 50);
                }
            },
          judgeBlockTagHighlightClick: function (e) {
            let blockTagHighlightElements = this.getAllBlockTagHighlightElements();
            //获取点击点在哪些alert的高亮区域里面
            for (let i = 0; i < blockTagHighlightElements.length; i++) {
              let highlightEleRect = blockTagHighlightElements[i].getBoundingClientRect()
              if (e.clientX <= highlightEleRect.right && e.clientX >= highlightEleRect.left - 3
                  && e.clientY <= highlightEleRect.bottom + 3 && e.clientY >= highlightEleRect.top) {
                this.setActiveBlockTag($(blockTagHighlightElements[i]).attr('data-id'), highlightEleRect);
                return;
              }
            }
            this.setActiveBlockTag(null);
          },
            markSuccess: function (alert) {
                let _this = this;
                this.localAddAlerts([alert]);
                this.$nextTick(()=>{
                    for(let currentAlert of this.allAlertData){
                        if(currentAlert.id === alert.id){
                            this.alertClick(currentAlert);
                            break
                        }
                    }
                    this.$nextTick(()=>{
                        setTimeout(function (){
                            _this.getAlertBoxById(alert.id).find(".customCommentInfo").focus()
                        },500)
                    })
                })
            },
            //更新自定义批注的内容
            saveCustomCommentMark: function (event,alert){
                let newAlertMsg = $(event.target).text();
                if(newAlertMsg.length > 100){
                    layer.msg("备注内容过长，已为您保留前100字")
                    newAlertMsg = newAlertMsg.substring(0,100)
                }
                $(event.target).text(newAlertMsg)
                updateCustomCommentMark(this.fileId,alert.id,newAlertMsg).then(res => {
                    if(res){
                        alert.alertMessage = newAlertMsg;
                    }else{
                        layer.msg("修改失败，请刷新文档后重试");
                    }
                })

            },
            //pdf js 的某一页已经渲染完毕
            pageRendered: function(pageNum){
                let highlightAlertData = this.alertData;
                let expandAlert =  this.getExpandAlert();
                if (this.highlightMode === HighlightMode.ALERT_BLOCK){
                    highlightAlertData = [this.currentHighlightAlertBlockData];
                    expandAlert = this.currentHighlightAlertBlockData;
                }

                this.refreshErrorHighlight(highlightAlertData);
                if (expandAlert && expandAlert.calculateInfo.pageIndexInfo && expandAlert.calculateInfo.pageIndexInfo[0].startPage === pageNum + 1) {
                    //如果展开的提示和加载出来的页面是同一页，则滚动过去。
                    this.highlightClick(expandAlert.id, 500);
                }
                this.refreshBlockTagHighlight(this.blockTags);
            },
            addNeedConvertRange: function (markRanges, needConvertedAlertRanges, rangeSizes) {
                if (markRanges && markRanges.length > 0 && !(markRanges[0].start === 0 && markRanges[0].end === 0)) {
                    needConvertedAlertRanges.push.apply(needConvertedAlertRanges, markRanges);
                    rangeSizes.push(markRanges.length);
                } else {
                    rangeSizes.push(0);
                }
            },
          refreshBlockTag:function (blockTags){
            if (!this.openBlockTag) {
              return;
            }
            const convertedBlockTagRangInfo = this.pdfInfo.fullTextIndexesConvertToPageIndexInfo(blockTags);
            for (let i = 0; i < blockTags.length; i++) {
              blockTags[i].calculateInfo={'pageIndexInfo': [convertedBlockTagRangInfo[i]]}
            }
            this.blockTags = blockTags;
          },
            // 刷新提示数据
            refreshAlertData: function (newAlertData) {
                let self = this
                // alert需要转换的range和每一个alert的range的size
                let alertRanges = [], alertRangeSizes = [];
                // alert的blockList中的block的range，和每一个block的range的size
                let alertSubBlockRanges = [],alertSubBlockRangeSizes = [];
                // 折叠提示中的子alert的range，和每一个子alert的range的size。
                let foldSubAlertRanges = [], foldSubAlertRangeSizes = [];
                newAlertData.forEach((info) => {
                    let markRanges = info.markRanges
                    this.addNeedConvertRange(markRanges, alertRanges, alertRangeSizes);

                    let alertSubBlockList = this.getAlertSubBlockList(info);
                    alertSubBlockList.forEach(block => {
                        if (block.message_type === BlockMessageType.HIT) {
                            this.addNeedConvertRange(block.markRanges,alertSubBlockRanges, alertSubBlockRangeSizes);
                        }
                    });
                    // 添加折叠提示的转换
                    let foldAlertList = this.getFoldSubAlertList(info);
                    foldAlertList.forEach(subAlert => {
                      if (subAlert.markRanges && subAlert.markRanges.length > 0) {
                        this.addNeedConvertRange(subAlert.markRanges,foldSubAlertRanges, foldSubAlertRangeSizes);
                      }
                    });
                })
                const convertedAlertRangeInfo = self.pdfInfo.fullTextIndexesConvertToPageIndexInfo(alertRanges)
                const convertedAlertSubBlockListRangeInfo = self.pdfInfo.fullTextIndexesConvertToPageIndexInfo(alertSubBlockRanges)
                const convertedFoldSubAlertListRangeInfo = self.pdfInfo.fullTextIndexesConvertToPageIndexInfo(foldSubAlertRanges)
                let alertRangeIndex = 0,alertSubBlockListRangeIndex=0,alertSubBlockRangeSizeIndex=0,foldSubAlertRangeIndex = 0,foldSubAlertRangeSizesIndex=0;
                newAlertData.forEach((info,index) => {
                    info.calculateInfo = {};
                    info.calculateInfo.status = AlertDisplayStatus.COLLAPSE
                    if (alertRangeSizes[index] > 0) {
                        info.calculateInfo.pageIndexInfo = convertedAlertRangeInfo.slice(alertRangeIndex, alertRangeIndex + alertRangeSizes[index]);
                    }
                    let alertSubBlockList = this.getAlertSubBlockList(info);
                    alertSubBlockList.forEach(block => {
                        block.calculateInfo = {};
                        if (block.message_type === BlockMessageType.HIT){
                            //将alert的id付给block，方便左右对齐等操作。
                            block.id = info.id;
                            // 点击某个block的时候总是处于展开状态，便于高亮左边划线区域
                            block.calculateInfo.status = AlertDisplayStatus.EXPAND
                            let alertSubBlockListRangeEndIndex = alertSubBlockListRangeIndex + alertSubBlockRangeSizes[alertSubBlockRangeSizeIndex];
                            block.calculateInfo.pageIndexInfo = convertedAlertSubBlockListRangeInfo.slice(alertSubBlockListRangeIndex, alertSubBlockListRangeEndIndex);
                            alertSubBlockListRangeIndex = alertSubBlockListRangeEndIndex;
                            alertSubBlockRangeSizeIndex++;
                        }
                        if(block.ref_clause){
                            block.calculateInfo.clauseText = block.ref_clause;
                        }
                    });
                    let foldSubAlertList = this.getFoldSubAlertList(info);
                  foldSubAlertList.forEach(subAlert => {
                      subAlert.calculateInfo = {};
                      if (subAlert.markRanges && subAlert.markRanges.length > 0) {
                        // 点击某个block的时候总是处于展开状态，便于高亮左边划线区域
                        subAlert.calculateInfo.status = AlertDisplayStatus.EXPAND
                        let foldSubAlertListRangeEndIndex = foldSubAlertRangeIndex + foldSubAlertRangeSizes[foldSubAlertRangeSizesIndex];
                        subAlert.calculateInfo.pageIndexInfo = convertedFoldSubAlertListRangeInfo.slice(foldSubAlertRangeIndex, foldSubAlertListRangeEndIndex);
                        foldSubAlertRangeIndex = foldSubAlertListRangeEndIndex;
                        foldSubAlertRangeSizesIndex++;
                      }
                    });

                    this.parseErrorTypeDescription(info);
                    this.parseNonStandardClause(info);
                    this.setExpandDisplayType(info);
                    this.parseRefClauseText(info);

                    alertRangeIndex = alertRangeIndex + alertRangeSizes[index];
                })
                // 恢复alert的展开状态
                self.restoreExpandStatus(newAlertData)
                //只保留
                this.refreshErrorStatistic(newAlertData)
                newAlertData = self.filterAlertData(newAlertData)
                self.alertData = newAlertData
            },
            // 当前alert是否有一个block的列表来用来展开后显示。
            getAlertSubBlockList: function (alert) {
                if (!alert.extensionInfo) {
                    return [];
                }
                let detailType = alert.extensionInfo['detailType']
                if (detailType && detailType === AlertExtensionType.BLOCK_LIST) {
                    return alert.extensionInfo[AlertExtensionType.BLOCK_LIST]
                }
                return [];
            },
           getFoldSubAlertList:function (alert){
             if (!alert.extensionInfo) {
               return [];
             }
             let errorType = alert.errorType
             if (ErrorType.FOLD_ALERT === errorType) {
               return alert.extensionInfo['foldAlertExtension']['alerts']
             }
             return [];
           },
            //解析非标条款的条款类型
            parseNonStandardClause: function(alert){
                if(!alert.extensionInfo){
                    return;
                }
                if (alert.extensionInfo[AlertExtensionType.CLAUSE_TYPE]){
                    alert.calculateInfo.clauseType = alert.extensionInfo[AlertExtensionType.CLAUSE_TYPE];
                }
            },
            //解析合同错误的子标题
            parseErrorTypeDescription:function(alert){
                if(!alert.extensionInfo){
                    return;
                }
                if (alert.extensionInfo[AlertExtensionType.SHORT_MSG]) {
                    alert.calculateInfo.errorTypeSubDescription = alert.extensionInfo[AlertExtensionType.SHORT_MSG];
                }
            },
            copyClauseTexts:function(){
                this.copyStringToClipboard(this.clauseDialogTexts.join("\n"));
            },
            copyStringToClipboard:function(str){
                let input = document.createElement('textarea')
                input.value =str;
                document.body.appendChild(input)
                input.select()
                document.execCommand('Copy')
                document.body.removeChild(input);
                this.$message.success('复制成功');
            },
            // 解析条款示例
            parseRefClauseText:function(alert){
                // alert.calculateInfo.clauseText ="甲方是经监管机构批准，在中华人民共和国境内依法设立的从事吸收公众存款、办理结算等业务的金融机构。\n乙方是依法取得《支付业务许可证》，获准办理_银行卡收单业务及专业化服务，互联网支付、移动电话支付、预付卡受理";
                if(!alert.extensionInfo){
                    return;
                }
                if (alert.extensionInfo[AlertExtensionType.REF_CLAUSE]) {
                    alert.calculateInfo.clauseText = alert.extensionInfo[AlertExtensionType.REF_CLAUSE];
                }
              // if(alert.extensionInfo["foldAlertExtension"]){
              //   alert.extensionInfo["foldAlertExtension"]["alerts"][0].alertMessage = "建议明确约定争议出现时率先谈判和解，不成的，约定以仲裁或者是诉讼的方式来进行；同时还要约定哪里的法院拥有管辖权。";
              //   alert.extensionInfo["foldAlertExtension"]["alerts"][0].legalBasis = "《中华人民共和国民法典》第四百七十条：\n" +
              //       "合同的内容由当事人约定，一般包括下列条款：\n" +
              //       "（一）当事人的姓名或者名称和住所；\n" +
              //       "（二）标的；\n" +
              //       "（三）数量；\n" +
              //       "（四）质量；\n" +
              //       "（五）价款或者报酬；\n" +
              //       "（六）履行期限、地点和方式；\n" +
              //       "（七）违约责任；\n" +
              //       "（八）解决争议的方法。\n" +
              //       "当事人可以参照各类合同的示范文本订立合同。";
              //   alert.extensionInfo["foldAlertExtension"]["alerts"][0].modificationSuggestion = "由本合同引起的或与本合同有关的争议均应通过协商或调解来解决。如果协商或调解未取得任何合同双方可以接受的结果，则上述争议应通过下述第    种方式解决：\n" +
              //       "（1）向【】方住所地人民法院提起诉讼；\n" +
              //       "（2）向【】仲裁委员会提请仲裁。";
              //   alert.briefMessage = "验收主体是对标的物进行验收的重要要素。一般由收货一方（买受人）负责验收，当然结合具体情况，也可请卖方和第三方共同验收。"
              //   let dummyAlert2 = Object.assign({}, alert.extensionInfo["foldAlertExtension"]["alerts"][0]);
              //   dummyAlert2.id = 'xxxfdasfasdfsdf'
              //   alert.extensionInfo["foldAlertExtension"]["alerts"].push(dummyAlert2)
              // }
            },
            //设置展开后的显示类型
            setExpandDisplayType:function(alert){
                if (!alert.extensionInfo) {
                    alert.calculateInfo.expandDisplayType = ExpandDisplayType.DEFAULT
                    return;
                }
                let detailType = alert.extensionInfo['detailType'];
                // 如果是类似于前置审批的错误
                if (detailType === AlertExtensionType.PRE_APPROVAL||detailType === AlertExtensionType.CONFUSE_TERM) {
                    let info = alert.extensionInfo[alert.extensionInfo['detailType']];
                    if (info != null) {
                        alert.calculateInfo.expandDisplayType = ExpandDisplayType.MODEL1;
                        alert.calculateInfo.expandDisplayModelInfo1 = {
                            data: info, showCompleteContent: false
                        };
                        return;
                    }
                }

                // 如果是需要显示多行文本
                if (detailType === AlertExtensionType.BLOCK_LIST) {
                    alert.calculateInfo.expandDisplayType = ExpandDisplayType.MODEL_BLOCK_LIST;
                    alert.calculateInfo.expandDisplayModelInfo2 = alert.extensionInfo[detailType];
                    return;
                }

                // let info = null;
                // if (alert.extensionInfo[AlertExtensionType.PRE_APPROVAL]) {
                //     //如果有前置审批的信息，则将显示状态切换成前置审批的状态
                //     info = alert.extensionInfo[AlertExtensionType.PRE_APPROVAL];
                // }else if(alert.extensionInfo[AlertExtensionType.CONFUSE_TERM]){
                //     info = alert.extensionInfo[AlertExtensionType.CONFUSE_TERM];
                // }
                // if (info != null) {
                //     alert.calculateInfo.expandDisplayType = ExpandDisplayType.MODEL1;
                //     alert.calculateInfo.expandDisplayModelInfo1 = {
                //         data: info, showCompleteContent: false
                //     };
                //     return;
                // }

                alert.calculateInfo.expandDisplayType = ExpandDisplayType.DEFAULT;
            },
            refreshErrorStatistic: function (newAlertData) {
                let errorStatistic = {
                };
                for (let type in ErrorType) {
                    errorStatistic[ErrorType[type]] = 0;
                }
                let ignoreCount = 0;
                newAlertData.forEach(alert => {
                    if(alert.ignore){
                        ignoreCount++;
                    }else{
                        errorStatistic[alert.errorType] += 1;
                        if (ErrorType.NONSTANDARD_CLAUSE !== alert.errorType) {
                            errorStatistic[ErrorType.ALL] += 1;
                        }
                    }
                })
                errorStatistic[ErrorTypeMenuItemKey.IGNORE] = ignoreCount
                this.errorStatistic = errorStatistic;
            },
            filterAlertByType:function (errorType){
                this.showAlertType = errorType;
                this.showIgnoreAlert = false;
                this.refreshAlertData(this.allAlertData)
                this.resetAlertPosition();
                this.hideMoreAlert();
                this.emitAlertDataRefreshedEvent();
            },
            filterAlertByIgnore:function(ignore){
                this.showIgnoreAlert = ignore;
                this.refreshAlertData(this.allAlertData)
                this.resetAlertPosition();
                this.hideMoreAlert();
            },
            getErrorLatestStatistic:function(){
                this.refreshErrorStatistic(this.allAlertData);
                return this.errorStatistic;
            },
            /**
             * 本地添加错误提示
             * @param {[{Alert}]} alerts
             */
            localAddAlerts:function(alerts){
                // this.allAlertData.push(alerts);
                let newData = alerts.concat(this.allAlertData);
                newData.sort((a1, a2) => a1.start - a2.start);
                this.refreshAlertData(newData);
            },
            hideOrShowDownloadButton: function(alertData){
                if (alertData.filter(a => !a.ignore).length > 0) {
                    $(".downloadCheckFile").show()
                }else{
                    $(".downloadCheckFile").hide()
                }
            },
            refreshAlertDataMap: function (alertData){
                let res = new Map();
                for (let i = 0; i < alertData.length; i++) {
                    res.set(alertData[i].id, alertData[i]);
                }
                this.alertDataMap = res;
            },
          refreshBlockTagHighlight(blockTags){
            $(`.${this.blockTagHighlightClass}`).remove()
            if (blockTags.length === 0) {
              return
            }
            const self = this;
            //先获取所有加载完毕的页码
            const visiblePageNum = this.pdfInfo.getRenderFinishPageNum(true);
            //根据已经加载出来的页码，来过滤能够显示出来的错误提示
            let canMarkBlockTags = blockTags.filter(a => {
              if (!a.calculateInfo.pageIndexInfo) {
                return false;
              }
              return visiblePageNum.indexOf(a.calculateInfo.pageIndexInfo[0].startPage) !== -1;
            })
            canMarkBlockTags.forEach(blockTag => {
              blockTag.calculateInfo.pageIndexInfo.forEach(pageIndexInfo=>{
                //将页内坐标（包含空格）修复成页内元素坐标（不包含空格）
                const pageRanges = this.pdfInfo.getFixedPageRanges(pageIndexInfo)
                if (pageRanges == null) {
                  //说明该页尚未加载完成，也就不用画错误提示。
                  return;
                }
                //根据该坐标去取到对应的document的range对象。
                let documentRanges = this.pdfInfo.getRangeAndAppendLayers(pageRanges,visiblePageNum, true,this.blockTagHighlightLayerClassName);
                documentRanges.forEach(rangeInfo =>{
                  //根据range对象对应的矩形区域画出错误提示。
                  self.createBlockTagMarker(rangeInfo.appendLayer,rangeInfo.documentRange.getClientRects(),blockTag);
                })
              })
            });
          },
          createBlockTagMarker: function (appendLayer, selectRangeRects, blockTag) {
            const offset = appendLayer.offset();
            let leftOffset = offset.left;
            let topOffset = offset.top;
            selectRangeRects = Array.from(selectRangeRects).sort((a,b)=>{
              if (Math.abs(a.top - b.top) > (a.height + b.height) * 0.5 * 0.5) {
                return a.top - b.top;
              }
              if(a.left!==b.left) {
                return a.left - b.left;
              }
              return 0
            })
            const mergedRects =   this.mergeRectsByAdjacent(selectRangeRects);
            for (let i = 0; i < mergedRects.length; i++) {
              let mergedRect = mergedRects[i];
              let top = mergedRect.top - topOffset;
              let blockTagClasses = `${this.blockTagHighlightClass} `;
              if (i === 0) {
                blockTagClasses += ' firstBlockTag '
              }
              appendLayer.append(
                  `<div class='${blockTagClasses}' data-id="${blockTag.id}" style='left:${mergedRect.left - leftOffset}px;top:${top}px;width:${mergedRect.width}px;height:${mergedRect.height}px;'></div>`
              );
            }
          },
            /**
             * 刷新划线区域
             * @param {[{id,calculateInfo,showType,errorType}]} alertData
             */
            refreshErrorHighlight: function (alertData){
                //删除旧的highlight节点
                $(`.${this.alertHighlightClass}`).remove()
                if (alertData.length === 0) {
                    return
                }

                const self = this;
                //先获取所有加载完毕的页码
                const visiblePageNum = this.pdfInfo.getRenderFinishPageNum(true);
                //根据已经加载出来的页码，来过滤能够显示出来的错误提示
                let canMarkAlerts = alertData.filter(a => {
                    if (!a.calculateInfo.pageIndexInfo) {
                        return false;
                    }
                    return visiblePageNum.indexOf(a.calculateInfo.pageIndexInfo[0].startPage) !== -1;
                })
                //在已经加载出的页面内画出对应错误提示。
                canMarkAlerts.forEach(alert => {
                    alert.calculateInfo.pageIndexInfo.forEach(pageIndexInfo=>{
                        //将页内坐标（包含空格）修复成页内元素坐标（不包含空格）
                        const pageRanges = this.pdfInfo.getFixedPageRanges(pageIndexInfo)
                        if (pageRanges == null) {
                            //说明该页尚未加载完成，也就不用画错误提示。
                            return;
                        }
                        //根据该坐标去取到对应的document的range对象。
                        let documentRanges = this.pdfInfo.getRangeAndAppendLayers(pageRanges,visiblePageNum, true,this.checkHighlightLayerClassName);
                        documentRanges.forEach(rangeInfo =>{
                            //根据range对象对应的矩形区域画出错误提示。
                            self.createAlertMarker(rangeInfo.appendLayer,rangeInfo.documentRange.getClientRects(),alert);
                        })
                    })
                });
            },
            createAlertMarker: function (appendLayer, selectRangeRects, alert) {
                const offset = appendLayer.offset();
                let leftOffset = offset.left;
                let topOffset = offset.top;
                let pageWidth = appendLayer.width();
                const self = this
                const lineBoxType = this.getAlertShowType(alert)
                const alertLinBoxClasses = self.getAlertHighlightClassName(alert); // 刚创建时不高亮
                selectRangeRects = Array.from(selectRangeRects).sort((a,b)=>{
                    if (Math.abs(a.top - b.top) > (a.height + b.height) * 0.5 * 0.5) {
                        return a.top - b.top;
                    }
                    if(a.left!==b.left) {
                        return a.left - b.left;
                    }
                    return 0
                })
                const mergedRects = lineBoxType === AlertShowType.VERTICAL ? mergeRectsByVerticalShowType(selectRangeRects, {left:leftOffset,width:pageWidth}) : this.mergeRectsByAdjacent(selectRangeRects);
                for (let i = 0; i < mergedRects.length; i++) {
                    let mergedRect = mergedRects[i];
                    let top = mergedRect.top - topOffset;
                    appendLayer.append(
                        `<div class='${alertLinBoxClasses}' data-id="${alert.id}" style='left:${mergedRect.left - leftOffset}px;top:${top}px;width:${mergedRect.width}px;height:${mergedRect.height}px;'></div>`
                    )
                }
            },
            /**
             * 对获取到的DOMRectList里面的DOMRect进行去重，防止同一个位置出现多个选区选中,并将左右相邻很近的rect尽量连接起来。
             * @param {DOMRectList} selectRangeRects
             */
            mergeRectsByAdjacent: function (selectRangeRects){
                if (selectRangeRects.length<2) {
                    return selectRangeRects
                }
                let result = [];
                let mergeRect={
                    top:selectRangeRects[0].top,
                    left:selectRangeRects[0].left,
                    width:selectRangeRects[0].width,
                    height:selectRangeRects[0].height
                };
                for (let i = 1; i < selectRangeRects.length; i++) {
                    let domEle = selectRangeRects[i]
                    if (this.rectCanMerge(mergeRect, domEle)) {
                        //如果前一个rect和现在的rect能够merge，则对它们进行融合
                        let maxBottom = Math.min(mergeRect.top + mergeRect.height, domEle.top + domEle.height);
                        let minTop = Math.min(mergeRect.top, domEle.top);

                        let maxRight = Math.max(mergeRect.left + mergeRect.width, domEle.left + domEle.width);
                        let minLeft = Math.min(mergeRect.left, domEle.left);

                        mergeRect.top = minTop;
                        mergeRect.height = maxBottom - minTop;
                        mergeRect.left = minLeft;
                        mergeRect.width = maxRight - minLeft;
                    }else{
                        //如果不能够merge，则将mergeRect作为一个range放到结果里面
                        result.push(Object.assign({}, mergeRect));
                        //并将当前rect作为mergeRect
                        mergeRect.top = domEle.top;
                        mergeRect.left = domEle.left;
                        mergeRect.width = domEle.width;
                        mergeRect.height = domEle.height;
                    }
                }
                //将最后一个rect放入结果中。
                result.push(Object.assign({}, mergeRect));
                return result;
            },
            /**
             * 判断两个矩形是否相交
             * @param {{top,left,width,height}} rect1 第一个矩形
             * @param {{top,left,width,height}} rect2 第二个矩形
             * @returns {boolean}
             */
            rectCanMerge: function(rect1,rect2){
                //x方向，两个矩形的中心点的距离
                const xCenterDistance = Math.abs((rect2.left + rect2.width / 2) - (rect1.left + rect1.width / 2))
                //x方向，两个矩形的边长的一半
                const xHalfOfTotalWidth = (rect1.width + rect2.width) / 2;
                //y方向，两个矩形的中心点的距离
                const yCenterDistance = Math.abs((rect2.top + rect2.height / 2) - (rect1.top + rect1.height / 2))
                //y方向，两个矩形的边长的一半
                const yHalfOfTotalHeight = (rect1.height + rect2.height) / 2;
                //x方向可以融合的宽度差，这里把高度作为一个字的宽度，取一个字的百分之70作为可以融合的宽度差
                const mergeWidthDiff = yHalfOfTotalHeight * 0.7;
                const maxHeightDiff = yHalfOfTotalHeight * 0.1;
                //如果相交，则两个中心点之间的距离肯定小于等于该方向的边长和的一半。
                return xCenterDistance - mergeWidthDiff <= xHalfOfTotalWidth && yCenterDistance + maxHeightDiff <= yHalfOfTotalHeight;
            },
            getAlertShowType: function (alert) {
                if (AlertShowType.VERTICAL === alert.showType) {
                    return AlertShowType.VERTICAL
                }else if (AlertShowType.DOC === alert.showType){
                    return AlertShowType.DOC
                }else if (AlertShowType.NONE === alert.showType) {
                    return AlertShowType.NONE
                }
                return AlertShowType.HORIZONTAL;
            },
            getAlertHighlightClassName:function(alert){
                let classArray = [this.alertHighlightClass];
                const lineBoxType = this.getAlertShowType(alert)
                classArray.push(lineBoxType === AlertShowType.VERTICAL?'verticalLineBox':'_3F-Wk')
                if (AlertDisplayStatus.EXPAND === alert.calculateInfo.status) {
                    classArray.push('_3GrEs');
                }
                if(ErrorType.TYPO === alert.errorType){
                    classArray.push('errorType_color_new_1');
                }else if(ErrorType.PUNCTUATION_MISUSE === alert.errorType){
                    classArray.push('errorType_color_new_2');
                }else if(ErrorType.WORD_ORDER_ERROR === alert.errorType){
                    classArray.push('errorType_color_new_3');
                }else if(ErrorType.SYNONYMOUS_CONFUSION === alert.errorType||ErrorType.CONTRACT_RISK === alert.errorType){
                    classArray.push('errorType_color_new_4');
                }else if(ErrorType.GRAMMAR_ERROR === alert.errorType||ErrorType.CUSTOM_COMMENT === alert.errorType){
                    classArray.push('errorType_color_5');
                } else if (alert.errorType && alert.errorType > 100||ErrorType.NONSTANDARD_CLAUSE === alert.errorType) {
                    classArray.push('errorType_color_100');
                }else if (ErrorType.STANDARD === alert.errorType){
                    classArray.push('errorType_color_new_5');
                } else{
                    classArray.push('errorType_color_100');
                }
                return classArray.join(" ")
            },
            getAlertBorderClass:function(alert){
                let borderClass = '';
                if(ErrorType.TYPO === alert.errorType){
                    borderClass=(alert.calculateInfo.status === AlertDisplayStatus.EXPAND?'alertItemExpandBorderLeft_new_1':'alertItemCollapseBorderLeft_new_1');
                }else if(ErrorType.PUNCTUATION_MISUSE === alert.errorType){
                    borderClass=(alert.calculateInfo.status === AlertDisplayStatus.EXPAND?'alertItemExpandBorderLeft_new_2':'alertItemCollapseBorderLeft_new_2');
                }else if(ErrorType.WORD_ORDER_ERROR === alert.errorType){
                    borderClass=(alert.calculateInfo.status === AlertDisplayStatus.EXPAND?'alertItemExpandBorderLeft_new_3':'alertItemCollapseBorderLeft_new_3');
                }else if(ErrorType.SYNONYMOUS_CONFUSION === alert.errorType||ErrorType.CONTRACT_RISK === alert.errorType){
                    borderClass=(alert.calculateInfo.status === AlertDisplayStatus.EXPAND?'alertItemExpandBorderLeft_new_4':'alertItemCollapseBorderLeft_new_4');
                }else if(ErrorType.GRAMMAR_ERROR === alert.errorType||ErrorType.CUSTOM_COMMENT === alert.errorType){
                    borderClass=(alert.calculateInfo.status === AlertDisplayStatus.EXPAND?'alertItemExpandBorderLeft_5':'alertItemCollapseBorderLeft_5');
                } else if (alert.errorType && alert.errorType > 100||ErrorType.NONSTANDARD_CLAUSE === alert.errorType) {
                    borderClass=(alert.calculateInfo.status === AlertDisplayStatus.EXPAND?'alertItemExpandBorderLeft_6':'alertItemCollapseBorderLeft_6');
                }else if (ErrorType.STANDARD === alert.errorType){
                    borderClass=(alert.calculateInfo.status === AlertDisplayStatus.EXPAND?'alertItemExpandBorderLeft_new_5':'alertItemCollapseBorderLeft_new_5');
                }
                let result ={}
                if (borderClass === '') {
                    return result;
                }
                result[borderClass]=true;
                return result;
            },
            getAlertHighlightJItem:function(alertId){
                return $(`.${this.alertHighlightClass}[data-id=${alertId}]`);
            },
            getHighlightRef(){
                return {
                  getHighlightRef:(alertId)=>{
                    const jqObj = this.getAlertHighlightJItem(alertId);
                    return jqObj.get();
                  }
                }
            },
            getAlertById:function(alertId){
                return this.alertDataMap.get(alertId);
            },
            getAlertBoxById:function(alertId){
                return $(this.$refs['alertInfoBox' + alertId])[0].getDomElement();
            },
            getAlertIdByHighlightJItem:function(highlightJItem){
                return highlightJItem.attr('data-id')
            },
            // filterAlertByExcludeErrorType: function (alertData){
            //     return alertData.filter(a=>{
            //         return this.includeErrorType.indexOf(a.errorType) >=0;
            //     })
            // },
            filterAlertData: function (allAlertData) {
                this.allAlertData = allAlertData

                let filteredAlertData = Array.from(allAlertData);
                if (this.showIgnoreAlert) {
                    //如果是显示被忽略的错误提示
                    if (this.isHideAdvancedTips()){
                        return allAlertData.filter(item => item.ignore && item.errorType < ErrorType.ADVANCED_TIPS);
                    }
                    return allAlertData.filter(item=> item.ignore)
                }else{
                    //否则则隐藏忽略的提示。
                    filteredAlertData = allAlertData.filter(item => !item.ignore)
                }
                var newAlertData = [];
                if (this.showAlertType != ErrorType.ALL) {
                    if (this.showAlertType == ErrorType.ADVANCED_TIPS) {
                        filteredAlertData.forEach(item => {
                            if (item.errorType > this.showAlertType) {
                                newAlertData.push(item);
                            }
                        })
                    } else {
                        filteredAlertData.forEach(item => {
                            if (item.errorType === this.showAlertType) {
                                newAlertData.push(item)
                            }
                        })
                    }
                } else if (this.isHideAdvancedTips()) {
                    filteredAlertData.forEach(item => {
                        if (item.errorType < ErrorType.ADVANCED_TIPS && item.errorType !== this.ErrorType.NONSTANDARD_CLAUSE) {
                            newAlertData.push(item)
                        }
                    })
                } else {
                    return filteredAlertData.filter(item=>{
                        return item.errorType !== this.ErrorType.NONSTANDARD_CLAUSE;
                    })
                }
                return newAlertData
            },
            isHideAdvancedTips() {
                return this.userProduct.productType === 0 && !this.isTeamActive && !this.superUser;
            },
            restoreExpandStatus: function (newData) {
                let expandAlert = this.getExpandAlert()
                if (expandAlert) {
                    for (let i = 0; i < newData.length; i++) {
                        let data = newData[i]
                        if (data.id === expandAlert.id) {
                            data.calculateInfo.status = expandAlert.calculateInfo.status;
                            break;
                        }
                        if (data.start === expandAlert.start && data.end === expandAlert.end
                            && data.sourceText === expandAlert.sourceText && data.replaceText === expandAlert.replaceText
                            && data.alertMessage === expandAlert.alertMessage) {
                            data.calculateInfo.status = expandAlert.calculateInfo.status;
                            break;
                        }
                    }
                }
            },
            getAlertHighlights:function () {
                return $(`.${this.alertHighlightClass}`);
            },
          getAllBlockTagHighlightElements:function () {
            return $(`.${this.blockTagHighlightClass}`);
          },
          getBlockTagHighlightElements:function (blockTagId) {
            return $(`.${this.blockTagHighlightClass}[data-id=${blockTagId}]`);
          },
            getExpandAlert: function () {
                for (let i = 0; i < this.alertData.length; i++) {
                    if (this.alertData[i].calculateInfo.status === AlertDisplayStatus.EXPAND) {
                        return  this.alertData[i]
                    }
                }
                return  null;
            },
            removeAlertInfoById: function (alertId) {
                for (let i = 0; i < this.alertData.length; i++) {
                    if (this.alertData[i].id === alertId) {
                        this.alertData.splice(i, 1)
                    }
                }
                for (let i = 0; i < this.allAlertData.length; i++) {
                    if (this.allAlertData[i].id === alertId) {
                        this.allAlertData.splice(i, 1)
                    }
                }
            },
            removeAlertsByIds:function(alertIds){

                this.allAlertData = this.allAlertData.filter(a => alertIds.indexOf(a.id) < 0);

                let i = 0;
                while (i < this.alertData.length) {
                    if(alertIds.indexOf(this.alertData[i].id) >= 0){
                        //需要被删除
                        this.alertData.splice(i, 1)
                    }else{
                        i++;
                    }
                }
                // this.alertData = this.alertData.filter(a => alertIds.indexOf(a.id) < 0);
            },
            getEditorElement: function () {
                return $('#' + this.pdfInfo.pdfContainerId);
            },
            /**
             * 扩展标点符号提示
             * @param alerts
             * @returns {*}
             */
            extendPunctuationAlert: function (alerts,checkContent) {
                for (let i = 0; i < alerts.length; i++) {
                    let obj = alerts[i]
                    if (obj.errorType === ErrorType.PUNCTUATION_MISUSE && obj.sourceText.length === 1) {
                        this.extendWord(obj, true,checkContent)
                    }
                }
                return alerts
            },
            shouldExtendAlert: function (obj) {
                if (obj.alertType === AlertType.DELETE) {
                    return true
                }
                return false
            },
            getParagraphByCharIndex: function (wholeText, keywordStartIndex, keywordEndIndex) {
                let pList = wholeText.split('\n')
                let charIndex = 0
                for (let i = 0; i < pList.length; i++) {
                    let maxCharIndex = charIndex + pList[i].length
                    if (keywordStartIndex >= charIndex && keywordEndIndex <= maxCharIndex) {
                        return {
                            pText: pList[i],
                            pCharStartIndex: charIndex,
                            pCharEndIndex: maxCharIndex
                        }
                    }
                    charIndex = maxCharIndex
                }
                return null
            },
            extendWord: function (currentAlertObj, isActivity) {
                if (!currentAlertObj) {
                    return;
                }
                let self = this
                let expandAlertObj
                expandAlertObj = currentAlertObj
                try {
                    if (self.shouldExtendAlert(expandAlertObj) || isActivity) {
                        // 如果是建议删除的提示，则寻找删除的关键词前面的一个词
                        let wholeText = self.getEditorText()
                        let alertStart = expandAlertObj.start
                        let alertEnd = expandAlertObj.end + 1
                        let alertSourceText = expandAlertObj.sourceText

                        let pInfo = self.getParagraphByCharIndex(wholeText, alertStart, alertEnd)
                        let matchResult = null // 当前关键词前一个字或者后一个字为起点找一个关键词。
                        let selectStart = null, selectEnd = null, selectStr = null
                        let noEmptyBeforeKeyword = false, noEmptyAfterKeyword = false
                        let extendPosition = 0
                        if (pInfo) {
                            if (pInfo.pCharStartIndex < alertStart && !self.isPunctuation(wholeText[alertStart - 1])) {
                                // 说明前面还有字,就以关键词前一个字为起点找一个关键词
                                extendPosition = -1
                                noEmptyBeforeKeyword = true
                                selectStart = alertStart - 1
                                selectEnd = alertStart
                                selectStr = wholeText.substring(selectStart, selectEnd)
                                matchResult = self.extendWordByOffset(self.words, wholeText, selectStr, selectStart, selectEnd)
                            } else if (pInfo.pCharEndIndex > alertEnd && !self.isPunctuation(wholeText[alertEnd])) {
                                // 说明后面还有字,就以关键词后一个字为起点找一个关键词
                                extendPosition = 1
                                noEmptyAfterKeyword = true
                                selectStart = alertEnd
                                selectEnd = alertEnd + 1
                                selectStr = wholeText.substring(selectStart, selectEnd)
                                matchResult = self.extendWordByOffset(self.words, wholeText, selectStr, selectStart, selectEnd)
                            }
                        }
                        let mergeResult = null // 关键词扩展之后的结果
                        if (matchResult != null && matchResult.matchWord !== null) {
                            // 将匹配出来的词和删除的词合并在一起
                            let mergeStart = Math.min(matchResult.matchStart, alertStart)
                            let mergeEnd = Math.max(matchResult.matchEnd, alertEnd)
                            let mergeStr = wholeText.substring(mergeStart, mergeEnd)
                            mergeResult = {
                                mergeStr: mergeStr,
                                sourceStart: Math.abs(alertStart - mergeStart),
                                sourceEnd: Math.abs(alertEnd - mergeStart)
                            }
                        } else {
                            // 如果前面没有匹配到词语
                            if (noEmptyBeforeKeyword) {
                                // 如果关键词之前有字，扩展之前一个字
                                extendPosition = -1
                                mergeResult = {
                                    mergeStr: selectStr + alertSourceText,
                                    sourceStart: 1,
                                    sourceEnd: alertSourceText.length + 1
                                }
                            } else if (noEmptyAfterKeyword) {
                                // 如果关键词之后有字，扩展之后一个字
                                extendPosition = 1
                                mergeResult = {
                                    mergeStr: alertSourceText + selectStr,
                                    sourceStart: 0,
                                    sourceEnd: alertSourceText.length
                                }
                            } else {
                                // 如果之前之后都没有字，则不进行扩展
                                mergeResult = {
                                    mergeStr: alertSourceText,
                                    sourceStart: 0,
                                    sourceEnd: alertSourceText.length
                                }
                            }
                        }

                        var sourceText = mergeResult.mergeStr.substring(mergeResult.sourceStart, mergeResult.sourceEnd)
                        expandAlertObj.expandBeforeText = mergeResult.mergeStr.substring(0, mergeResult.sourceStart)
                        expandAlertObj.expandAfterText = mergeResult.mergeStr.substring(mergeResult.sourceEnd)
                        if (isActivity) {
                            if (extendPosition == -1) {
                                expandAlertObj.start -= expandAlertObj.expandBeforeText.length
                            } else if (extendPosition == 1) {
                                expandAlertObj.end += expandAlertObj.expandAfterText.length
                            }
                        }
                        return mergeResult
                    }
                } catch (e) {
                    console.error(e)
                    expandAlertObj.expandBeforeText = ""
                    expandAlertObj.expandAfterText = ""
                }
            },
            isPunctuation: function (str) {
                return str.match(/[。？！，、；：“”‘'（）《》〈〉 .?!,;:"'()<>]/g) !== null
            },
            extendWordByOffset: function (words, wholeText, selectStr, selectStart, selectEnd) {
                let extensionDirection = ExtensionDirection.Forward // 扩大当前选中区域方向
                // 匹配结果
                let matchResult = {
                    selectStr: selectStr,
                    matchWord: null,
                    matchStart: null,
                    matchEnd: null
                }
                if (words) {
                    // 先进行一次过滤，过滤掉不包含当前选中关键词的分词。
                    let waitMathWords = JSON.parse(words).filter(word => word.indexOf(selectStr) >= 0)
                    // 以初始选中文字为中心，想后扩张选区、并查看关键词数组里面有没有对应的关键词，
                    // 如果一个都没有，则回退坐标，反向扩张，直到两个方向都没有匹配的关键词。则保存最近一次匹配的关键词和位置
                    while (waitMathWords.length > 0) {
                        let subStr = wholeText.substring(selectStart, selectEnd)
                        // 将字符串和分词数组进行对比查看有没有完全对的上的。
                        let newWaitMathWord = []
                        let noOneMatch = true // 没有一个是匹配的
                        waitMathWords.forEach(word => {
                            if (word === subStr) {
                                matchResult.matchWord = subStr
                                matchResult.matchStart = selectStart
                                matchResult.matchEnd = selectEnd
                            }
                            if (word.indexOf(subStr) >= 0) {
                                newWaitMathWord.push(word)
                                noOneMatch = false
                            }
                        })
                        if (!noOneMatch) {
                            // 如果不是所有都匹配不上，就将过滤后的数组赋值给等待匹配词数组。
                            waitMathWords = newWaitMathWord
                        } else {
                            // 否则就回退index，并换一个方向继续测试是否有完全匹配的关键词。
                            if (extensionDirection === ExtensionDirection.Forward) {
                                extensionDirection = ExtensionDirection.Backend
                                selectEnd--
                            } else {
                                // 如果当前已经是向后延伸了，说明已经匹配完了。就退出循环
                                break
                            }
                        }
                        // 进行坐标向后递增或者先前递减，如果遇到超出范围就换个方向或者递减
                        if (extensionDirection === ExtensionDirection.Forward) {
                            selectEnd++
                            if (selectEnd > wholeText.length) {
                                selectEnd--
                                extensionDirection = ExtensionDirection.Backend
                            }
                        }
                        if (extensionDirection === ExtensionDirection.Backend) {
                            selectStart--
                            if (selectStart < 0) {
                                break
                            }
                        }
                    }
                }
                return matchResult
            },
            // 展开当前提示，收起其它提示
            expandAlert: function (alertId) {
                let self = this
                if (this.alertData.length <= 0) {
                    return
                }
                //清除旧的提示划线高亮状态。
                this.clearActiveHighlight();
                let expandAlertObj = null
                this.alertData.forEach(currentAlertObj => {
                    currentAlertObj.calculateInfo.status =
                        alertId === currentAlertObj.id
                            ? AlertDisplayStatus.EXPAND
                            : AlertDisplayStatus.COLLAPSE

                    let alertBox = $(self.getAlertBoxById(currentAlertObj.id))
                    if (alertId === currentAlertObj.id) {
                        expandAlertObj = currentAlertObj
                        if (currentAlertObj.calculateInfo.status != AlertDisplayStatus.EXPAND) {
                            currentAlertObj.calculateInfo.status = AlertDisplayStatus.EXPAND
                            if (!alertBox.hasClass('expandAlert')) {
                                alertBox
                                    .find('.animation-wrapper')
                                    .removeClass('zoomOutAndFadeAlert')
                                alertBox.addClass('expandAlert')
                            }
                        }
                    } else {
                        if (alertBox.hasClass('expandAlert')) {
                            alertBox.removeClass('expandAlert')
                            alertBox.find('.animation-wrapper').addClass('zoomOutAndFadeAlert')
                        } else {
                            currentAlertObj.calculateInfo.status = AlertDisplayStatus.COLLAPSE
                        }
                        //将第二形态content太长的文字重新隐藏
                        if (currentAlertObj.calculateInfo.expandDisplayModelInfo1 && currentAlertObj.calculateInfo.expandDisplayModelInfo1.showCompleteContent) {
                            currentAlertObj.calculateInfo.expandDisplayModelInfo1.showCompleteContent = false;
                        }
                        currentAlertObj.calculateInfo.status = AlertDisplayStatus.COLLAPSE;
                    }
                })
                // self.extendWord(expandAlertObj, false)
                // self.washGrammarError(expandAlertObj)
            },
            clearActiveHighlight: function () {
                const highlightBox = $(`.${this.alertHighlightClass}._3GrEs`);
                if (highlightBox) {
                    highlightBox.removeClass('_3GrEs');
                }
            },
            washGrammarError: function (alertObj) {
                if (alertObj.errorType !== ErrorType.GRAMMAR_ERROR) {
                    return
                }
                if (alertObj.replaceText !== null) {
                    return;
                }
                var params = {
                    'text': alertObj.sourceText,
                    'washingType': 1,
                    'washingStyle': 1
                }
                axios.post(`${this.checkBaseUrl}/getWashingText`, params).then(res => {
                    alertObj.replaceText = res.data.result
                })
            },
            isShowSpacePoint: function (alert) {
                if (alert.errorType === ErrorType.LAW_DEFINE) {
                    return false
                }
                return true
            },
            getAlertSourceTextDesc: function (alertEntity) {
                return alertEntity.sourceText;
                // let sourceText = alertEntity.sourceText
                // if (alertEntity.errorType === ErrorType.PUNCTUATION_MISUSE && alertEntity.sourceText.length === 1) {
                //     return alertEntity.expandBeforeText + sourceText + alertEntity.expandAfterText
                // } else {
                //     return sourceText
                // }
            },
            isShowReplaceText(alertEntity) {
                return alertEntity.alertType === AlertType.REPLACE && alertEntity.errorType !== ErrorType.GRAMMAR_ERROR
            },
            getAlertReplaceTextDesc: function (alertEntity) {
                return alertEntity.replaceText;
                // let replaceText = alertEntity.replaceText
                // if (alertEntity.errorType === ErrorType.PUNCTUATION_MISUSE && alertEntity.sourceText.length === 1) {
                //     return alertEntity.expandBeforeText + replaceText + alertEntity.expandAfterText
                // } else {
                //     return replaceText
                // }
            },
            getCollapseLeftText:function(alert){
                if (alert.errorType === ErrorType.CONTRACT_RISK||alert.errorType === ErrorType.NONSTANDARD_CLAUSE) {
                    //如果是合同错误，则左边显示 错误类型，如主体检查
                    return this.getErrorTypeDesc(alert)
                }else{
                    //如果不是合同错误，则第一部分显示sourceText
                    return this.getAlertSourceTextDesc(alert)
                }
            },
            getCollapseMediumText:function(alert){
                if (alert.errorType === ErrorType.CONTRACT_RISK||alert.errorType === ErrorType.NONSTANDARD_CLAUSE){
                    //如果是合同错误，合同错误可能分为三级，eg: 合同检查->主体检查->公司名变更
                    //如果有错误类型子描述，则显示错误类型子描述（比如例子中的公司名变更）
                    if (alert.calculateInfo.errorTypeSubDescription) {
                        return alert.calculateInfo.errorTypeSubDescription
                    }else{
                        //如果没有，就显示建议检查
                        return this.getAlertTypeDesc(alert);
                    }
                }
                return this.getErrorTypeDesc(alert)
            },
            //获取建议的子标题
            getCollapseRightText:function(alert){
                this.getAlertReplaceTextDesc(alert)
            },
            getAlertTypeDesc: function (alert) {
                let alertType = alert.alertType;
                switch (alertType) {
                    case AlertType.OTHER:
                        return '其它错误';
                    case AlertType.INSERT_BEFORE:
                        return '建议划线处前添加';
                    case AlertType.INSERT_AFTER:
                        return '建议划线处后添加';
                    case AlertType.DELETE:
                        return '建议删除';
                    case AlertType.REPLACE:
                        if (alert.calculateInfo.status === 0 && alert.errorType !== ErrorType.GRAMMAR_ERROR) {
                            if (alert.errorType == ErrorType.WORD_ORDER_ERROR) {
                                return '建议交换为';
                            } else {
                                return '建议替换为';
                            }
                        } else {
                            if (alert.errorType == ErrorType.WORD_ORDER_ERROR) {
                                return '建议交换';
                            } else {
                                return '建议替换';
                            }
                        }
                    case AlertType.NO_OPERATION:
                        if (alert.errorType === ErrorType.LAW_DEFINE) {
                            if (alert.calculateInfo.status === 0) {
                                return '法律定义'
                            }
                            return ''
                        } else if (alert.errorType === ErrorType.SERIAL_ERROR && alert.calculateInfo.status === 0) {
                            return '序号问题'
                        }
                        if (alert.calculateInfo.errorTypeSubDescription) {
                            return alert.calculateInfo.errorTypeSubDescription
                        }
                        return '建议检查';
                    case AlertType.WRAP:
                        return '缺少符号';
                    default:
                        return '其它错误'
                }
            },
            getErrorTypeDesc: function (alert) {
                if (alert.errorTypeDescription) {
                    return alert.errorTypeDescription
                }
                switch (alert.errorType) {
                    case ErrorType.ALL:
                        return '所有提示';
                    case ErrorType.OTHER:
                        return '其它错误';
                    case ErrorType.TYPO:
                        return '字词错误';
                    case ErrorType.PUNCTUATION_MISUSE:
                        return '标点误用';
                    case ErrorType.WORD_ORDER_ERROR:
                        return '语序问题';
                    case ErrorType.SYNONYMOUS_CONFUSION:
                        return '同义混淆';
                    case ErrorType.GRAMMAR_ERROR:
                        return '语法问题';
                    case ErrorType.DATE_ERROR:
                        return '日期错误';
                    case ErrorType.AMOUNT_ERROR:
                        return '金额错误';
                    case ErrorType.BRAND_ERROR:
                        return '专有名词规范';
                    case ErrorType.DISUNITY_ERROR:
                        return '重复定义';
                    case ErrorType.SERIAL_ERROR:
                        return '序号问题';
                    case ErrorType.LAW_DEFINE:
                        return '法律定义';
                    case ErrorType.STANDARD:
                        return '规范检查';
                    case ErrorType.CONTRACT_RISK:
                        return '合同风险';
                    case ErrorType.NONSTANDARD_CLAUSE:
                        return '非标条款';
                    case ErrorType.CUSTOM_COMMENT:
                        return '自定义批注';
                    default:
                        return '其它错误' ;
                }
            },
            getShowSourceText: function (alertInfo) {
                let alertEntity = alertInfo
                let sourceText = alertEntity.sourceText
                let replaceText = alertEntity.replaceText
                if (AlertType.REPLACE === alertEntity.alertType && ErrorType.WORD_ORDER_ERROR === alertEntity.errorType) {
                    // 如果是语序错误。
                    let point = replaceText.indexOf(sourceText.substring(0, 1))
                    return replaceText.substring(point)
                } else {
                    return sourceText
                }
            },
            getShowReplaceText: function (alertInfo) {
                let alertEntity = alertInfo
                let sourceText = alertEntity.sourceText
                let replaceText = alertEntity.replaceText
                if (AlertType.REPLACE === alertEntity.alertType && ErrorType.WORD_ORDER_ERROR === alertEntity.errorType) {
                    // 如果是语序错误。
                    let point = replaceText.indexOf(sourceText.substring(0, 1))
                    return replaceText.substring(0, point)
                } else if (alertEntity.errorType === ErrorType.GRAMMAR_ERROR) {
                    if (alertEntity.replaceText === null) {
                        return "加载中"
                    }
                }
                return replaceText
            },
            //获取真正显示的提示信息
            getRealShowAlertMessage:function(alertInfo){
                const msg = alertInfo.alertMessage
                if (alertInfo.errorType === ErrorType.LAW_DEFINE) {
                    return this.getLawDefineDesc(msg)
                }
                //获取alertMessage的click信息
                if(!alertInfo.extensionInfo){
                    return msg;
                }
                if (alertInfo.extensionInfo[AlertExtensionType.MAIN_RISK]){
                    let clickInfo = alertInfo.extensionInfo[AlertExtensionType.MAIN_RISK]
                    const keyIndex = msg.indexOf(clickInfo.key)
                    const textBeforeKey = msg.substring(0,keyIndex)
                    const textAfterKey = msg.substring(keyIndex+clickInfo.key.length)
                    return `${textBeforeKey}${this.generateAlertMessageKeyClickElement(clickInfo)}${textAfterKey}`
                }
                return msg;
            },
            generateAlertMessageKeyClickElement:function(clickInfo){
                if (clickInfo.type === AlertMsgClickType.OPEN_COMPANY_META_INFO) {
                    return `<a href='javascript:void(0)' class="color_blue" onclick="openCompanyMetaInfo('${clickInfo.companyName}','${clickInfo.tag}')">${clickInfo.key}</a>`;
                }
                return clickInfo.key
            },
            getLawDefineDesc: function (lawDefineStr) {
                let lawDefineDetail = JSON.parse(lawDefineStr)
                let lawSourceUrl = metaCloudConfig.neptuneDomain + "/#/searchlist?searchTag=laws&searchKeys=" + lawDefineDetail.source + "&sf=top&defaultTag=laws"
                return "【来源法规】：<a href='" + lawSourceUrl + "' target='_blank'>" + lawDefineDetail.source + "</a></br>" + "【效力级别】：" + lawDefineDetail.level + "</br>" + "【定义】：" + lawDefineDetail.define
            },
            checkCheckerEachLimit: function (checkTotalWord, checkEachLimit) {
                let self = this
                if (checkTotalWord > checkEachLimit) {
                    var checkOverLimitTips = self.getCheckOverLimitTips(false, false, checkTotalWord, checkEachLimit, true)
                    self.checkOverTitle = checkOverLimitTips[0]
                    self.checkOverDescription = checkOverLimitTips[1]
                    self.checkOverLimitDrawer = true
                    return true
                } else {
                    self.checkOverLimitDrawer = false
                    return false
                }
            },
            checkCheckerDailyLimit: function (dayCheckLimitExceed, dayTeamCheckLimitExceed, todayCheckedWordCount) {
                let self = this
                localStorage.setItem('todayCheckedWordCount', todayCheckedWordCount)
                // 放行，单日和单次同时超限，优先提示单日超限
                // if (self.checkOverLimitDrawer) {
                //   return
                // }
                if (dayCheckLimitExceed || dayTeamCheckLimitExceed) {
                    var checkOverLimitTips = self.getCheckOverLimitTips(dayCheckLimitExceed, dayTeamCheckLimitExceed, 0, 0, false)
                    self.checkOverTitle = checkOverLimitTips[0]
                    self.checkOverDescription = checkOverLimitTips[1]
                    self.checkOverLimitDrawer = true
                    return true
                } else if (!self.checkOverLimitDrawer) {
                    self.checkOverLimitDrawer = false
                    return false
                }
            },
            getCheckOverLimitTips: function (dayCheckLimitExceed, dayTeamCheckLimitExceed, checkWordsLength, checkEachLimit, eachOverLimit) {
                var overLimitTitle = ''
                var overLimitDescription = ''
                var userProduct = this.userProduct
                if (eachOverLimit) {
                    // 单次查错超限
                    overLimitTitle = '查错超限，已为您检查前【' + checkEachLimit + '】字'
                    if (this.isTeamActive || userProduct.productType == 2) {
                        // 双语用户||团队用户
                        if (this.isTeamActive) {
                            overLimitTitle = '查错超限，已为您检查前【' + checkEachLimit + '】字'
                        }
                        overLimitDescription = '超过上限（' + checkWordsLength + '字）：您可以分批处理'
                    } else {
                        overLimitTitle = '查错超限，已为您检查前【' + checkEachLimit + '】字，立即升级'
                        overLimitDescription = '【' + '写作猫-' + userProduct.productName + '】超过上限（' + checkWordsLength + '字）：好饿，猫粮快没了，投喂升级'
                    }
                } else {
                    // 单日查错超限
                    overLimitTitle = '超过单日上限，明日再试'
                    if (this.isTeamActive && dayTeamCheckLimitExceed) {
                        overLimitDescription = '团队今日查错额度已用尽，请明日再试'
                    } else if (this.isTeamActive && dayCheckLimitExceed) {
                        overLimitDescription = '今日查错额度已用尽，可联系团队管理员提升单日额度'
                    } else {
                        if (userProduct.productType == 0 || userProduct.productType == 1) {
                            overLimitDescription = '【' + '写作猫-' + userProduct.productName + '】超过上限：好饿，猫粮快没了，投喂升级'
                        } else if (userProduct.productType == 2) {
                            overLimitDescription = '超过上限'
                        }
                    }
                }
                return [overLimitTitle, overLimitDescription]
            },
            showOverLimitTips: function () {
                if (this.translationOverLimitDrawer) {
                    this.overLimitTitle = this.translateOverTitle
                    this.overLimitDescription = this.translateOverDescription
                    this.checkTranslationOverLimitDrawer = true
                } else if (this.checkOverLimitDrawer) {
                    this.overLimitTitle = this.checkOverTitle
                    this.overLimitDescription = this.checkOverDescription
                    this.checkTranslationOverLimitDrawer = true
                } else if (this.rewriteOverLimitDrawer) {
                    this.overLimitTitle = this.rewriteOverTitle
                    this.overLimitDescription = this.rewriteOverDescription
                    this.checkTranslationOverLimitDrawer = true
                } else {
                    this.checkTranslationOverLimitDrawer = false
                }
            },
            playRemoveHighlineAnimation: function (alertInfo) {
                let self = this
                if (alertInfo['alertType'] === AlertType.NO_OPERATION || (alertInfo['alertType'] === AlertType.REPLACE && alertInfo['replaceText'] === null)) {
                    return;
                }
                self.inOperation = true
                var id = alertInfo['id']
                var removeHighlight = self.getEditorElement().find("div[data-id='" + id + "']")// 找到划线的元素
                var alertPanel = $('.ue-alert-container').find("div[data-id='" + id + "']")// 找到当前面板
                $(removeHighlight).removeClass('_3GrEs')
                setTimeout(() => {
                    $(removeHighlight).addClass('_3GrEs')
                    $(alertPanel).addClass('alertPanelHide')
                }, 1)

                // setTimeout(() => {
                //     self.acceptReplace(alertInfo)
                // }, 300)
            },
            addClauseFunc:function(alert){
                addClause(this.fileId, alert.calculateInfo.clauseType, alert.sourceText).then(res=>{
                    if (res.code !== 0) {
                        layer.msg(res.msg)
                        console.error('添加到条款库错误,res' + JSON.stringify(res));
                        return
                    }
                    const alertId = alert.id
                    this.removeAlertsWithAnimation([alertId], () => {
                        this.reCheck()
                    });
                }).fail(error=>{
                    layer.msg('添加失败')
                    console.error('添加到条款库错误')
                    console.error(error);
                });
            },
            removeAlertsWithAnimation: function (alertIds,callback) {
                const highlightJItems = alertIds.map(a=>this.getAlertHighlightJItem(a))
                for (let j = 0; j < alertIds.length; j++) {
                    highlightJItems[j].removeClass('_3GrEs')
                }
                setTimeout(()=>{
                    for (let j = 0; j < alertIds.length; j++) {
                        highlightJItems[j].addClass('_3GrEs')
                        //让错误提示动画消失
                        this.getAlertBoxById(alertIds[j]).animate({height:'0',padding:'0'},300)
                    }
                    setTimeout(() => {
                        this.removeAlertsByIds(alertIds)
                    }, 300);
                    setTimeout(callback,500)
                },10)
            },
            twinklePage:function(){
                $('.page').addClass('_3GrEs')
                setTimeout(() => {
                    $('.page').removeClass('_3GrEs')
                }, 500);
            },
            /**
             * 忽略提示信息
             * @param alert
             * @param ignore 是否忽略
             */
            ignoreAlert:function(alert,ignore){
                // 接受提示。
                axios.post("/ignoreAlert", {
                    fileId: this.fileId, alertId: alert.id, ignore: ignore
                }).then(res=>{
                    alert.ignore = ignore
                    if (ignore) {
                        //如果是将提示忽略,那么此刻应该处于显示非忽略提示状态下
                        this.filterAlertByType(this.showAlertType)
                    }else{
                        //如果是撤销忽略操作,那么此刻应该处于显示忽略提示状态下
                        this.filterAlertByIgnore(true)

                    }
                    this.hideOrShowDownloadButton(this.alertData);
                }).catch(error=>{
                    console.error(error)
                })
            },
            forceUpdateDelay:function(time=10){
                setTimeout(()=>this.$forceUpdate(),time);
            },
            switchHighlightMode:function(mode){
                if (this.highlightMode !== HighlightMode.ALERT && mode === HighlightMode.ALERT) {
                    this.highlightMode = mode;
                    this.refreshErrorHighlight(this.alertData);
                }
                this.highlightMode = mode;
            },
            alertClick: function (alertObj) {
                console.log('alertClick  alertObj === ', alertObj);
                if (!(this.highlightMode === HighlightMode.ALERT_BLOCK && this.currentHighlightAlertBlockData.id === alertObj.id)) {
                    this.switchHighlightMode(HighlightMode.ALERT);
                }
                let time = 500
                //先冻结掉对scroll事件的监听
                // this.freezeScrollListenerFunc(time)
                //先获取所有加载完毕的页码
                let alertShowType = this.getAlertShowType(alertObj);
                if (AlertShowType.NONE === alertShowType) {
                    // 如果是不显示划线，那么alertClick之后只是展开该消息提示详情。
                    this.expandAlert(alertObj.id);
                    //强制刷新一下，为了解决（切换不同的错误类型后，有时alert的展开状态里面的值已经更改，但是ui不刷新bug。）
                    this.forceUpdateDelay();
                    this.moveAlertCanViewAll(alertObj.id);
                    return;
                }
                if (AlertShowType.DOC === alertShowType) {
                    //如果全文级别的错误
                    this.expandAlert(alertObj.id);
                    this.twinklePage();
                    //强制刷新一下，为了解决（切换不同的错误类型后，有时alert的展开状态里面的值已经更改，但是ui不刷新bug。）
                    this.forceUpdateDelay();
                    this.moveAlertCanViewAll(alertObj.id);
                    return;
                }
                const visiblePageNum = this.pdfInfo.getRenderFinishPageNum(true);
                if (alertObj.calculateInfo.pageIndexInfo && visiblePageNum.indexOf(alertObj.calculateInfo.pageIndexInfo[0].startPage) < 0) {
                    this.expandAlert(alertObj.id)
                    this.pdfInfo.scrollByPage(alertObj.calculateInfo.pageIndexInfo[0].startPage)
                    // return // TODO：此处有问题，return了没有highlight
                }

                setTimeout(() => {
                    let highlightEle;
                    try {
                        highlightEle = this.getAlertHighlightJItem(alertObj.id)
                    } catch (e) {
                        console.error('getAlertHighlightJItem e:', e);
                        this.refreshAlertData(this.allAlertData)
                        highlightEle = this.getAlertHighlightJItem(alertObj.id)
                    }
                    // // 移动屏幕外的错误到当前窗口
                    if (this.isHighlightEleOutOfScreen(highlightEle)) {
                        this.editorScrollToAbsolutePosition(this.getContentScrollTopByMarkerItem(highlightEle),time)
                        time = 0
                    }
                    this.highlightClick(alertObj.id, time)
                }, 500)

            },
            blockPointerClass:function(alertChildBlock){
                // 注释掉miss block的clauseText功能
                // return alertChildBlock.message_type === BlockMessageType.HIT || alertChildBlock.calculateInfo.clauseText;
                return alertChildBlock.message_type === BlockMessageType.HIT;
            },
            // alertChildBlockClicked:function(alertInfo,alertChildBlock){
            //     if (alertChildBlock.message_type === BlockMessageType.HIT) {
            //         this.alertHitChildBlockClicked(alertInfo,alertChildBlock);
            //     } else if (alertChildBlock.message_type === BlockMessageType.MISS) {
            //         this.alertMissChildBlockClicked(alertInfo,alertChildBlock);
            //     }
            // },
            alertHitChildBlockClicked:function({alert, childBlock}){
                this.switchHighlightMode(HighlightMode.ALERT_BLOCK);
                let blockConvertedAlert = {
                    id: alert.id,
                    calculateInfo: childBlock.calculateInfo,
                    showType: childBlock.showType,
                    errorType: ErrorType.LAW_DEFINE,
                };
                this.currentHighlightAlertBlockData = blockConvertedAlert;
                if (!blockConvertedAlert.calculateInfo.pageIndexInfo || blockConvertedAlert.calculateInfo.pageIndexInfo.length < 1) {
                  return;
                }
                // 先滑动到对应的页
                this.pdfInfo.scrollByPage(blockConvertedAlert.calculateInfo.pageIndexInfo[0].startPage)
                this.refreshErrorHighlight([blockConvertedAlert])
                this.$nextTick(()=>{
                    this.alertClick(blockConvertedAlert);
                })
            },
            alertMissChildBlockClicked:function(alertInfo,alertChildBlock){
                // 先注释掉block的clauseText功能，改到alert级别去了。以后可能会打开。
                // if (alertChildBlock.calculateInfo.clauseText) {
                //     this.showClauseTextDialog(alertChildBlock.calculateInfo.clauseText)
                // }
            },
            getContentScrollTopByMarkerItem: function (highlightEle) { // 移动正文容器到能够显示关键词
                let jqueryObj = $(highlightEle)
                let scrollerTop = this.getScrollerScrollTop() // 正文滚动的距离
                let markerOffset = jqueryObj.offset().top // 当前错误关键词到可见区域顶部的距离
                let scrollerOffset = this.getScrollerOffsetTop()// 正文容器到可见区域顶部的距离
                let contentHeight = this.getScrollerHeight()// 正文容器高度
                return markerOffset + scrollerTop - scrollerOffset - contentHeight / 2
            },
            getScrollerJItem: function () {
                // return $('#' + this.scrollerId);
                const scrollerJItem = $('#' + this.pdfInfo.pdfContainerId);
                return scrollerJItem;
            },
            // 正文容器到可见区域顶部的距离
            getScrollerOffsetTop: function () {
                return this.getScrollerJItem().offset().top;
            },
            // 正文滚动的距离
            getScrollerScrollTop: function () {
                return this.getScrollerJItem().scrollTop()
            },
            getFirstAlertOffsetOfScreenTop:function (){
                return 50 + 200;
            },
            //编辑器距离顶部的距离（也就是编辑器最开始的时候的offset.top）
            getEditorInitOffsetTop: function (){
                return this.getScrollerScrollTop() + this.getEditorElement().offset().top;
            },
            // 正文容器高度
            getScrollerHeight: function () {
                // let editorElement = document.getElementById(this.richTextEditorId)
                // let offsetTop = this.getElemetTop(editorElement)
                // let contentHeight = document.body.clientHeight - offsetTop
                // if ($('.editor-toolbox').length > 0) {
                //     contentHeight -= $('.editor-toolbox').height()
                // }
                // return contentHeight
                return this.getScrollerJItem().height()
            },
            editorScrollToAbsolutePosition: function (scrollTop, time) {
              let editorElement = this.getEditorElement();
                if (time > 0) {
                    this.freezeScrollListenerFunc(time)
                    $(editorElement).animate({'scrollTop': scrollTop}, time)
                } else {
                    this.freezeScrollListenerFunc(500)
                    $(editorElement).scrollTop(scrollTop)
                }
            },
            editorScrollToRelativePosition: function (addScrollTop, time) {
                let currentTop = this.getScrollerScrollTop()
                let realScrollTop = currentTop + addScrollTop
                this.editorScrollToAbsolutePosition(realScrollTop, time)
                // this.freezeScrollListenerFunc(time)
                return realScrollTop
            },
            /**
             * 错误关键词是否在屏幕之外
             */
            isHighlightEleOutOfScreen: function (highlightEle) {
                let scrollerOffset = this.getScrollerOffsetTop() // 正文容器到可见区域顶部的距离
                let jqueryObj = $(highlightEle)
                let markerOffset = jqueryObj.offset().top // 当前错误关键词到可见区域顶部的距离
                return markerOffset > this.getScrollerHeight() || markerOffset < scrollerOffset
            },
            highlightClick: function (currentId, time) {
              this.emitAlertClickEvent();

              let highlight = this.getAlertHighlightJItem(currentId)
                if (highlight) {
                    this.expandAlert(currentId)
                    // highlight['hover'] = true
                    this.lineHighlight(currentId)
                    //强制刷新一下，为了解决（切换不同的错误类型后，有时alert的展开状态里面的值已经更改，但是ui不刷新bug。）,以及让展开的提示ui展开，方便正确的计算高度。
                    this.$forceUpdate();
                    this.hideMoreAlert()
                    //先冻结掉对scroll事件的监听
                    // this.freezeScrollListenerFunc(time)
                    // highlight['hover'] = true
                    // this.expandAlert(highlight.id)
                    this.moveAlertToHighlight(currentId, time)
                }
            },
          getBlockTagById:function (id){
            return this.blockTagsMap.get(id);
          },
          setActiveBlockTag:function (id){
            const activeClass = "active";
            this.getAllBlockTagHighlightElements().removeClass(activeClass)
            if (id == null) {
              this.activeBlockTag = null;
              return;
            }
            this.scrolledAfterBlockTagClick=false;
            let blockTagElements = this.getBlockTagHighlightElements(id);
            if (blockTagElements.length === 0) {
              return;
            }
            blockTagElements.addClass(activeClass)

            const highlightRect = blockTagElements[0].getBoundingClientRect();
            this.activeBlockTag = this.blockTagsMap.get(id);
            this.$nextTick(()=>{
              this.activeBlockTagPosition.top = highlightRect.top - this.$refs.tagContainer.clientHeight - 10;
              this.activeBlockTagPosition.left = highlightRect.left
            })
          },
            lineHighlight:function(alertId){
                let expandAlert = this.getExpandAlert();
                if (expandAlert) {
                    let oldHighlight = this.getAlertHighlightJItem(expandAlert.id);
                    oldHighlight.removeClass('_3GrEs')
                }

                let newHighlight = this.getAlertHighlightJItem(alertId);
                newHighlight.addClass('_3GrEs')
            },
            moveAlertToHighlight: function (alertId, time) {
                let _this = this
                let highlightEle = _this.getAlertHighlightJItem(alertId);
                let highlightItemOffsetTop = highlightEle.offset().top;
                let top = _this.getAlertContainerMoveByMarkerItemOffsetTop(highlightItemOffsetTop)
                setTimeout(function () {
                    _this.moveAlert(alertId, top, highlightItemOffsetTop, time)
                }, 50)
            },
            //移动提示，使我们能够看到整个提示框。
            moveAlertCanViewAll: function (alertId) {

                const alertBox = this.getAlertBoxById(alertId)
                const alertBoxJItem = $(alertBox);
                const alertOffsetTop = alertBoxJItem.offset().top;
                const alertHeight = alertBoxJItem.outerHeight(true)
                const winHeight = $(window).height()
                let destTop
                if (alertOffsetTop < 150) {
                    //如果在屏幕上面
                    destTop = 150
                }else if (alertOffsetTop + alertHeight >= winHeight) {
                    //如果在屏幕下面
                    destTop = winHeight - alertHeight * 1.5;
                }else{
                    //如果本身就能完全看到,就不需要处理
                    return
                }

                let top = this.getAlertContainerMoveByMarkerItemOffsetTop(destTop);
                // this.freezeScrollListenerFunc(time)
                this.$nextTick(()=>{
                    // this.freezeScrollListenerFunc(time)
                    this.moveAlert(alertId, top, destTop, 500)
                })
            },
            //将错误提示移动到当前屏幕
            resetAlertPosition: function(){
                this.alertsOffsetTop = this.getAlertContainerMoveByMarkerItemOffsetTop(this.getScrollerOffsetTop() + this.getFirstAlertOffsetOfScreenTop());
            },
            getAlertContainerMoveByMarkerItemOffsetTop: function (highlightItemOffsetTop) {
                let scrollerTop = this.getScrollerScrollTop() // 正文滚动的距离
                return scrollerTop + highlightItemOffsetTop - this.getFirstAlertOffsetOfScreenTop();
                //
                // let scrollerOffset = this.getEditorInitOffsetTop()// 编辑器到可见区域顶部的距离
                // console.log('scrollerOffset === ', scrollerOffset);
                // return scrollerTop + highlightItemOffsetTop - scrollerOffset -this.getFirstAlertOffsetOfScreenTop()
            },
            initAlertsContainerInitOffsetTop: function () {
                this.$nextTick(() => {
                    this.alertsContainerInitOffsetTop = $(`#${this.alertOffsetContainerId}`).offset().top;
                });
            },
            // 移动错误提示
            moveAlert: function (alertId, translateY, highlightItemOffsetTop, time) {
                let self = this
                translateY = this.convertToInt(translateY)
                let alertLength = this.alertData.length
                let preAlertBoxHeight = 0
                // 先找到alertId对应数据信息在alertData里面的index，并计算这之前的所有提示框的总高度
                for (let index = 0; index < alertLength; index++) {
                    let currentAlertObj = this.alertData[index]
                    let currentAlertBox = self.getAlertBoxById(currentAlertObj.id)
                    if (alertId === currentAlertObj.id) {
                        if (currentAlertBox) {
                            preAlertBoxHeight += $(currentAlertBox).outerHeight(true) * 0.4;
                        }
                        break
                    }
                    if (currentAlertBox) {
                        preAlertBoxHeight += $(currentAlertBox).outerHeight(true)
                    }
                }
                let realTranslateY = translateY - preAlertBoxHeight;
                let moveHeight = highlightItemOffsetTop - (this.alertsContainerInitOffsetTop + 60)// 为顶部时上一个alertdata的预留位
                if (moveHeight < 0) {
                    self.editorScrollToRelativePosition(moveHeight, time)
                }
                let winHeight = $(window).height()
                moveHeight = highlightItemOffsetTop - winHeight + 180// winHeight- 184为底部时下一个alertdata的预留位
                if (moveHeight > 0) {
                    self.editorScrollToRelativePosition(moveHeight, time)
                }
                // $(".ue-alert-container").animate({'margin-top': realTranslateY}, 500)
                console.log('this.alertsOffsetTop === ', realTranslateY);
                this.alertsOffsetTop = realTranslateY
            },
            getPreAlertHeight:function(alertId){
                let preAlertBoxHeight = 0
                // 先找到alertId对应数据信息在alertData里面的index，并计算这之前的所有提示框的总高度
                for (let index = 0; index < this.alertData.length; index++) {
                    let currentAlertObj = this.alertData[index]
                    if (alertId === currentAlertObj.id) {
                        break
                    }
                    let currentAlertBox = this.getAlertBoxById(currentAlertObj.id)
                    if (currentAlertBox.length>0) {
                        preAlertBoxHeight += currentAlertBox.outerHeight(true)
                    }
                }
                return preAlertBoxHeight
            },
            convertToInt: function (numberStr) {
                if (typeof numberStr === 'string') {
                    return parseInt(numberStr)
                }
                return numberStr
            },
        }
    }
</script>

<style>
.blockTagHighlightContainer.firstBlockTag {
  border-left: 3px solid darkorange;
}

.blockTagHighlightContainer {
  position: absolute;
}
.blockTagHighlightContainer.active{
  background: rgba(10,132,255,0.5);
}
.tagContainer{
  position: absolute;
  padding:10px;
  background: whitesmoke;
  z-index: 100;
}
</style>
<style lang="scss" scoped>
    @import url("../../assets/css/alert.css");

    .contract-checker-container {
        padding-top: 10px;
        min-height: 300px;
        display: inline-block;
        /*top:132px;*/
        position:relative;
        width: 100%;
        top: 50px;
    }

    .contract-checker-container::-webkit-scrollbar {
        display: none;
    }

</style>
