// 合同文件类型
export const CompanyTableTemplateData = [
    {
        name1: "企业名称",
        key1: "name",
        value1: "",
        name2: "统一社会信用代码",
        key2: "creditCode",
        value2: "",
    },
    {
        name1: "法定代表人",
        key1: "operName",
        value1: "",
        name2: "经营状态",
        key2: "status",
        value2: "",
    },
    {
        name1: "成立日期",
        key1: "startDate",
        value1: "",
        name2: "行政区划",
        key2: "",
        value2: "",
    },
    {
        name1: "注册资本",
        key1: "registCapi",
        value1: "",
        name2: "实缴资本",
        key2: "",
        value2: "",
    },
    {
        name1: "企业类型",
        key1: "econKind",
        value1: "",
        name2: "所属行业",
        key2: "",
        value2: "",
    },
    {
        name1: "工商注册号",
        key1: "no",
        value1: "",
        name2: "组织机构代码",
        key2: "orgNo",
        value2: "",
    },
    {
        name1: "纳税人识别号",
        key1: "creditCode",
        value1: "",
        name2: "纳税人资质",
        key2: "",
        value2: "",
    },
    {
        name1: "营业期限",
        key1: "term",
        value1: "",
        name2: "核准日期",
        key2: "checkDate",
        value2: "",
    },
    {
        name1: "登记机关",
        key1: "belongOrg",
        value1: "",
        name2: "参保人数",
        key2: "",
        value2: "",
    },
    {
        name1: "曾用名",
        key1: "originalName",
        value1: "",
        name2: "曾用名",
        key2: "originalName",
        value2: "",
    },
    {
        name1: "注册地址",
        key1: "address",
        value1: "",
        name2: "注册地址",
        key2: "address",
        value2: "",
    },
    {
        name1: "经营范围",
        key1: "scope",
        value1: "",
        name2: "经营范围",
        key2: "scope",
        value2: "",
    },
];

export function parseToCompanyTableTemplateData(companyDetail) {
    const tableData = JSON.parse(JSON.stringify(CompanyTableTemplateData));
    for (const item of tableData) {
        if (item.key1 && companyDetail[item.key1]) {
            let value1 = companyDetail[item.key1];
            if (item.key1 === 'originalName' && value1) {
                value1 = value1.map(item => item.Name).join("、");
            }
            item.value1 = value1;
        }
        if (item.key2 && companyDetail[item.key2]) {
            let value2 = companyDetail[item.key2];
            if (item.key2 === 'originalName' && value2) {
                value2 = value2.map(item => item.Name).join("、");
            }
            item.value2 = value2;
        }

        if (item.key1 === 'term') {
            const termStart = companyDetail["termStart"];
            const teamEnd = companyDetail["teamEnd"];
            if (termStart && teamEnd) {
                item.value1 = termStart.substring(0, termStart.indexOf(" ")) + " 至 " + teamEnd.substring(0, teamEnd.indexOf(" "))
            }
        }
    }

    return tableData;
}
