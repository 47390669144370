<template>
  <div class="contract-inform-message-card">
    <div class="cimc-item">
      <div class="cimc-item-header">
        <i style="color:var(--el-color-warning)" class="cimc-item-header-icon"><svg-icon name="inform" height="20" width="20"></svg-icon></i>
        <strong class="cimc-item-userName truncate" :title="contractInform.userName">
          {{contractInform.editor}}
        </strong>
        <span class="cimc-item-last-modify-time">
          {{transformTimeInfoForList(contractInform.updateTime,Date.now())}}
        </span>
      </div>
      <div class="cimc-item-content">
        {{
          contractInform.message
        }}
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../SvgIcon/svgIcon";
import {transformTimeInfoForList} from "../../util";

export default {
  name: "ContractInformMessageCard",
  components: {SvgIcon},
  props:{
    contractInform:{
      required:false,
      default:{
        editor:'假数据1',
        updateTime:new Date('2022/06/26 19:30:10'),
        message:'数据1112222'
      }
    }
  },
  data(){
    return {
      transformTimeInfoForList
    }
  },
}
</script>

<style scoped>
.contract-inform-message-card{
  border-radius: 4px;
  border: 1px var(--el-color-warning) solid;
  text-align: left;
  background: var(--el-color-warning-light);
}
.cimc-item{
  padding-left: 40px;
}
.cimc-item-header{
  display: flex;
}
.cimc-item-header-icon{
  margin-left: -30px;
}
.cimc-item-userName{
  min-width: 0;
  margin-left: 10px;
}
.cimc-item-last-modify-time{
  margin-left: 10px;
  color:var(--gray-3);
  flex-shrink: 0;
}
</style>
