<template>
  <div class="contract-version-card custom-scroller">
    <div class="cvc-bar" @click="toggleCards">
      <div  class="cvc-bar-expand" >
        <img v-if="!showCard" class="relation_img" src="../../assets/images/sj-right.png"/>
        <img v-else class="relation_img" src="../../assets/images/sj-down.png"/>
      </div>
      <b class="cvc-bar-title">
        {{title}}
      </b>
    </div>
    <div class="version-cards" v-show="showCard">
      <div class="version-series"
           v-for="(contractVersion, index) in contractVersions" :key="contractVersion.id"
           :ref="setVersionSeries"
           :data-vid = "contractVersion.id"
      >
        <div
            class="contract_version_container"
            @click="handleCardClicked(
                                          contractVersion.id,
                                          contractVersion.fileType,
                                          contractVersion.fileName,
                                          contractVersion.contractId
                                        )"
            data-tag-id="contract-view-big-version-preview"
        >
          <div class="contract_version_item">
            <!--                                <div class="orderStyle">-->
            <!--                                  <div></div>-->
            <!--                                </div>-->
            <div class="version-index truncate">
              <slot name="title" :version="contractVersion">
                v{{contractVersion.bigVersion}}
              </slot>
            </div>
            <span class="contract-createTime">{{ contractVersion.createTime.slice(0,-3) }}</span>
            <el-tooltip  :content="getFileTitle(contractVersion)" popper-class="title-popover" placement="top" :append-to-body="false">
              <span class="version-title-tips">
               <svg xmlns="http://www.w3.org/2000/svg" class="styles__StyledSVGIconPathComponent-sc-16fsqc8-0 kPWqkE svg-icon-path-icon fill" viewBox="0 0 1024 1024" width="18" height="18"><defs data-reactroot=""></defs><g><path d="M809.010019 214.988446c-163.765831-163.79653-430.255231-163.79653-594.022085 0-163.79653 163.766854-163.79653 430.257278 0 594.021062 163.767877 163.798577 430.256254 163.798577 594.022085 0C972.806549 645.245723 972.806549 378.7553 809.010019 214.988446zM766.38113 766.382665c-140.265604 140.293233-368.528378 140.263557-508.764306 0-140.294256-140.265604-140.294256-368.499725 0-508.779655 140.235928-140.250254 368.497679-140.27993 508.764306 0C906.644687 397.853263 906.644687 626.145713 766.38113 766.382665z" p-id="2149"></path><path d="M514.170944 298.225195c-24.929786 0-45.217854 20.289092-45.217854 45.232181 0 24.989137 20.288069 45.307905 45.217854 45.307905 24.958438 0 45.278229-20.318768 45.278229-45.307905C559.449173 318.514287 539.129382 298.225195 514.170944 298.225195z" p-id="2150"></path><path d="M514.170944 427.28969c-18.801205 0-34.150798 15.32094-34.150798 34.151821l0 267.547499c0 18.828834 15.349593 34.149774 34.150798 34.149774 18.830881 0 34.180474-15.32094 34.180474-34.149774L548.351418 461.441511C548.351418 442.611653 533.001825 427.28969 514.170944 427.28969z" p-id="2151"></path></g></svg>
              </span>
            </el-tooltip>
          </div>
          <div class="version_info">
            <div class="contract_version_name">
                                    <span
                                        class="contract-creator"
                                    >
                                      {{ contractVersion.creatorName + '  保存'}}
                                    </span>
            </div>
            <div class="expand-button operation-btn"
                 @click.capture.stop="expandCard(contractVersion.id)"
            >
              <button v-if="contractVersion.smallVersions.length>0" data-tag-id="contract-view-big-version-expand">展开</button>
            </div>

            <div
                class="slot-button operation-btn"
                @click.capture.stop="handleBtnClicked(contractVersion,index)"
                v-if="btnPermission"
            >
              <slot name="button" :version="contractVersion"></slot>
            </div>
          </div>
        </div>

        <div class="contract_version_small hidden">
          <div class="contract_version_container"
               @click="handleCardClicked(
                                          item.id,
                                          item.fileType,
                                          item.fileName,
                                          item.contractId
                                        )"
               data-tag-id="contract-view-small-version-preview"
               v-for="(item,index) in contractVersion.smallVersions" :key="index">
            <div class="contract_version_item">
              <div class="version-index">v{{item.bigVersion}}.{{item.smallVersion}}</div>
              <span class="contract-createTime">{{ item.createTime.slice(0,-3) }}</span>
            </div>
            <div class="version_info">
              <div class="contract_version_name">
              </div>
              <div
                  class="slot-button"
                  @click.capture.stop="handleBtnClicked(item)"
                  v-if="btnPermission"
              >
                <slot name="button" :version="contractVersion"></slot>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div class="resolve-empty" v-if="contractVersions.length == 0">无{{title}}</div>
    </div>
  </div>
</template>

<script>
import {colorLog, getUniqueId} from "../../util";

export default {
  name: "ContractVersionCard",
  props:{
    contractVersions:{
      required:true,
    },
    btnPermission:{
      required:false,
      default:false
    },
    title:{
      required:false,
      default:''
    },
    btnDisable:{
      required:false,
      default:false
    }
  },
  data(){
    return {
      versionSeries:[],
      showCard:true,
    }
  },
  watch:{
    contractVersions:{
      handler:function(contractVersions){
        this.showCard = contractVersions.length > 0;
      },
      immediate:true
    }
  },
  methods:{
    handleCardClicked: function (id, fileType, fileName,contractId) {
      this.$emit('cardClicked',id, fileType, fileName,contractId);
    },
    handleBtnClicked(version,index){
      if(this.btnDisable)return;
      this.$emit('btnClicked',version,index+1);
    },
    setVersionSeries(el){
      this.versionSeries.push(el);
    },
    beforeUpdate() {
      this.versionSeries = [];
    },
    toggleCards(){
      this.showCard = !this.showCard;
    },
    expandCard(vid){
      this.versionSeries.find(n => n.getAttribute('data-vid') == vid).classList.toggle('expanded');
      // this.versionSeries[index].classList.toggle('expanded');
    },
    getFileTitle(contractVersion){
      if(contractVersion.group==='补充协议'){
        if(contractVersion.fileName!==null){
          return contractVersion.fileName.substring(0,contractVersion.fileName.lastIndexOf('.'))
        }
      }else{
        if(contractVersion.title!==null) {
          return contractVersion.title.substring(0, contractVersion.title.lastIndexOf('.'))
        }
      }
      return ""

    }
  }
}
</script>

<style scoped>
.relation_img{
  width: 20px;
  height: 20px;
  vertical-align: top;
}
.cvc-bar-expand{
  display: inline-block;
  cursor:pointer;
}
.cvc-bar{
  display: inline-block;
  margin-bottom: 5px;
}
.cvc-bar-title{
  display: inline-block;
  cursor:pointer;
  user-select: none;
}
.version-series:not(:first-child){
  margin-top: 5px;
}
.version-series.expanded .contract_version_small{
  display: block;
}

.version-series.expanded .expand-button button{
  border-color:var(--lightBlue);
  color:var(--lightBlue);
  background-color: var(--hoverLightBlue);
}
.version-series > .contract_version_container .version_info
{
  border-left: solid 1px #eee;
}
.contract-version-card{
  padding: 0 5px;
  text-align: left;
  /*max-height: 610px;*/
  /*overflow-y: auto;*/
}
.contract-version-card+.contract-version-card{
  margin-top: 10px;
}
.contract_version_container{
  transition: box-shadow .5s;
  background: #fff;
  padding: 5px;
  box-shadow: 0px 1px 3px 1px #eee;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}
.contract_version_small{
  margin: 0 0 0 15px;
  display: none;
}
.contract_version_small .contract_version_container{
  margin-top: 5px;
}
.contract_version_container:hover{
  box-shadow: 0px 1px 3px 3px #e3e0e0;
}
.contract_version_item {
  display: flex;
  align-content: center;
  align-items: center;
  padding-right: 2px;
  text-align: left;
}
.version-index{
  color:var(--lightBlue);
  min-width: 25px;
  font-weight: 600;
  margin:0 5px;
}
.version_info {
  display: flex;
  align-content: center;
  align-items: center;
  padding-right: 10px;
  margin-left: 10px;
  padding-left: 25px;
  font-weight: bold;
  font-size: 14px;
}
.contract-createTime{
  font-weight:bolder;
  flex: none;
}

.contract_version_name button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 250px;
  border: none;
  text-align: left;
}

.contract_version_name {
  margin: 10px 0;
  flex: 1;
  white-space: nowrap;

  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
.contract-creator{
  color:#aaa;
  font-weight: normal;
}
.expand-button button{
  font-size: 12px;
  border: 1px solid #dcdfe6;
  /*border-radius:3px;*/
  padding:7px 15px;
  line-height:1;
  color:#606266;
  background:white;
  cursor:pointer;
}

.operation-btn:not(:first-of-type){
  margin-left:5px;
}
.resolve-empty{
  color:var(--font-gray);
}

.version-title-tips{
  margin-left: 10px;
  height: 20px;
  color: rgb(137, 137, 137);
}

.contract_version_item >>> .title-popover{
  background: rgba(0, 0, 0, 0.6);
  max-width: 150px;
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 8px 0px
}


.contract_version_item >>> .title-popover .el-popper__arrow::before{
  background: transparent;
  border: 5px solid transparent;
  border-right-color: rgba(0, 0, 0, .6);
  border-bottom-color: rgba(0, 0, 0, .6);


}
</style>
