<template>
  <el-dialog title="合同状态设置" v-model="showDialog" width="500px">
    <div class="tip">
      <span>您要将当前合同状态变更为：</span>
    </div>
    <el-radio-group v-model="approvalStatus">
      <div class="container">

        <div v-for="(item,index) in statuses" :key="index" class="item">
          <el-radio :label="item.value" :disabled="item.disabled">
            <span :class="item.class"></span>
            <span>{{item.label}}</span>
          </el-radio>
        </div>

      </div>
    </el-radio-group>
    <div></div>
    <template #footer>
      <el-button @click="onBtnCancelClick">取消</el-button>
      <el-button type="primary" @click="onBtnOKClick" v-loading="loading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {ContractApprovalStatus} from "../../constant/contract";
import {
  approveContract,
  cancelContract, deleteResources,
  draftFinalized,
  finalizedContract,
  launchApprovalProcess,
  revertContract
} from "../../api/api";
import {ElMessage} from "element-plus";
export default {
  name: "ContractApprovalStatusSetting",
  emits:['approvalStatusChanged','approval-status-changed'],
  props:{
    contract:{
      type:Object,
      default:null
    }
  },
  data(){
    return{
      teamInfo:JSON.parse(sessionStorage.getItem("teamInfo")),
      approvalStatus:0,
      statuses:[
        {class:"stage-circle stage-gray",label:'草稿',value:0,},
        {class:"stage-circle  stage-yellow",label:'审批中',value:10,disabled:true},
        {class:"stage-circle stage-purple",label:'定稿',value:11},
        {class:"stage-circle  stage-red",label:'已驳回',value:12,disabled:true},
        {class:"stage-circle  stage-green",label:'已签署',value:21},
        {class:"stage-circle  stage-black",label:'已取消',value:30},
        {class:"stage-circle stage-white",label:'已删除',value:40},
      ],
      showDialog:false,
      loading:false,
    }
  },
  watch:{
    "contract":{
      deep:true,
      handler:function () {
        this.approvalStatus=this.contract.approvalStatus
        if(this.approvalStatus!==null){
          this.statuses[4].disabled=this.approvalStatus!==ContractApprovalStatus.LAUNCH_READY_TO_SIGN_PASSED||(this.teamInfo&&!this.teamInfo.finalizedNeedNotCommitNewVersion)
        }
      }
    }
  },
  mounted() {
  },
  methods:{
    open(){
      this.showDialog=true;
    },
    close(){
      this.showDialog=false;
    },
    onBtnOKClick: async function (){
      this.loading = true;
      if(this.approvalStatus===this.contract.approvalStatus){
        this.close();
        return;
      }
      let res = null;
      switch (this.approvalStatus){
        case ContractApprovalStatus.DRAFT:
          res = await revertContract(this.contract.id);
          break;
        case ContractApprovalStatus.LAUNCH_READY_TO_SIGN:
          res = await launchApprovalProcess(this.contract.id);
          break;
        case ContractApprovalStatus.LAUNCH_READY_TO_SIGN_PASSED:
          res = await draftFinalized(this.contract.id);
          break;
        case ContractApprovalStatus.LAUNCH_SIGNED_PASSED:
          res = await finalizedContract(this.contract.id);
          break;
        case ContractApprovalStatus.CANCELED: {
          this.$emit("cancelContract");
          this.close();
          return;
        }
        case 40:
          res = await deleteResources([{id:this.contract.id,resourceType: 4}],false);
          break;
        default:
          break;



      }
      this.loading = false;
      if(res.data && res.data.code==0){
        ElMessage.success("修改成功");
        this.$emit("approvalStatusChanged")
      }else{
        ElMessage.success("修改失败");
      }


    },
    onBtnCancelClick: function(){
      this.close()
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/contract.css";
.container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}
.tip{
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
}
.item{

  width: 150px;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

</style>
